import { Category, Picking, Product, ProductPricing, Story } from '@/domain/product/types';
import { SelectionItemBlock, SelectionItemProduct } from '@/domain/selection/types';
import { ShopDetails, ShopStats } from '@/domain/shop/types';
import { UserPublic } from '@/domain/user/types';

export const EEmbedsName = {
  Buyer: 'buyer',
  Categories: 'categories',
  Category: 'category',
  Items: 'items',
  Picking: 'picking',
  Pricing: 'pricing',
  Products: 'products',
  Seller: 'seller',
  Shop: 'shop',
  Statistics: 'statistics',
  Story: 'story',
  User: 'user',
} as const;

export interface Embeds {
  [EEmbedsName.Buyer]: UserPublic;
  [EEmbedsName.Categories]: Category[];
  [EEmbedsName.Category]: Category;
  [EEmbedsName.Items]: SelectionItemBlock[] | SelectionItemProduct[];
  [EEmbedsName.Picking]: Picking;
  [EEmbedsName.Pricing]: ProductPricing;
  [EEmbedsName.Products]: Product[];
  [EEmbedsName.Seller]: UserPublic;
  [EEmbedsName.Shop]: ShopDetails;
  [EEmbedsName.Statistics]: ShopStats;
  [EEmbedsName.Story]: Story;
  [EEmbedsName.User]: UserPublic;
}
