
import { ValueOf } from 'type-fest';
import Vue from 'vue';

const ELinkType = {
  Native: 'a',
  NuxtLink: 'NuxtLink',
} as const;

type LinkType = ValueOf<typeof ELinkType>;

// NOTE: How to create SEO links obfuscation while staying accessible. Please read : https://www.lalutineduweb.fr/en/seo-links-obfuscation-accessibility-problems/
export default Vue.extend({
  name: 'BaseLink',
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localHref: this.href, // Prop's value only used for data initialization.
    };
  },
  computed: {
    linkType(): LinkType {
      return this.to ? ELinkType.NuxtLink : ELinkType.Native;
    },
    isLocalHrefObfuscated(): boolean {
      return this.$services?.navigation?.isObfuscatedLink(this.localHref);
    },
    obfuscatedHref(): string | null {
      return this.isLocalHrefObfuscated ? this.href : null;
    },
    deobfuscatedHref(): string | null {
      return this.isLocalHrefObfuscated ? null : this.localHref;
    },
  },
  methods: {
    handleClick(event: Event): void {
      // NOTE: simple accessibility safe guard for users browsing with their voice (no click/focus/mouseover possible).
      if (this.isLocalHrefObfuscated) {
        this.$errorMonitor.report({
          message: 'Obfuscated link clicked',
          data: {
            href: this.href,
            link: this.localHref,
            currentRoute: this.$route.fullPath,
            userAgent: navigator.userAgent,
          },
        }, 'fatal');

        this.deobfuscate();

        window.location.href = this.localHref;
      }

      this.$emit('click', event);
    },
    handleFocus(event: Event): void {
      this.deobfuscate();

      this.$emit('focus', event);
    },
    handleMouseover(): void {
      this.deobfuscate();
    },
    deobfuscate(): void {
      this.localHref = this.$services?.navigation?.deobfuscateLink(this.href);
    },
  },
});
