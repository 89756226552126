import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { CurrencyCode, CurrencyRate, ECurrencyCode } from '@/domain/pricing/types';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { accessorType } from '@/store';

export interface Currency extends CurrencyRate {
  code: CurrencyCode;
}

export interface State {
  currencies: Currency[];
  selectedCurrencyCode: CurrencyCode;
}

const defaultCurrency: Currency = {
  code: ECurrencyCode.EUR,
  rate: 1,
};

export const state = (): State => ({
  currencies: [defaultCurrency],
  selectedCurrencyCode: ECurrencyCode.EUR,
});

export const getters = getterTree(state, {
  getSelectedCurrencyRate: ({ currencies, selectedCurrencyCode }): number => {
    const currency = currencies.find(({ code }) => code === selectedCurrencyCode) || { rate: defaultCurrency.rate };

    return currency.rate;
  },
  getCurrencyCodes: ({ currencies }): CurrencyCode[] => currencies.map(({ code }) => code),
});

export const mutations = mutationTree(state, {
  SET_CURRENCIES: (state, currencies?: Currency[]): void => {
    if (currencies?.length) {
      state.currencies = [defaultCurrency, ...currencies];
    }
  },
  SET_CURRENCY_CODE: (state, code: CurrencyCode): void => {
    state.selectedCurrencyCode = code;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchCurrencies(): Promise<void> {
    try {
      const accessor: typeof accessorType = this.app.$accessor;
      const currencies = await this.$repositories.currency.getCurrencyRates();

      accessor.pricing.SET_CURRENCIES(currencies as Currency[]);
    } catch (err) {
      this.$errorMonitor.report((err as HttpResponse)?.content || err, 'fatal');
    }
  },
  setCurrencyCode(_, code: CurrencyCode): void {
    const accessor: typeof accessorType = this.app.$accessor;
    const isValidCode = accessor.pricing.getCurrencyCodes.includes(code);

    accessor.pricing.SET_CURRENCY_CODE(isValidCode ? code : ECurrencyCode.EUR);
  },
});
