
import Vue from 'vue';

import IcChat from '@/assets/svg/ic_chat.svg?inline';
import IcHeart from '@/assets/svg/ic_heart.svg?inline';
import IcShop from '@/assets/svg/ic_shop.svg?inline';
import IcUser from '@/assets/svg/ic_user.svg?inline';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { SidebarComponent } from '@/store/ui';

export default Vue.extend({
  name: 'NavigationUser',
  components: {
    IcChat,
    IcHeart,
    IcShop,
    IcUser,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    isUserSeller: {
      type: Boolean,
      default: false,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormLogin: {
        component: () => import('@/components/organisms/form/FormLogin.vue'),
        id: ESidebarComponentId.FormLogin,
      },
      SidebarNavigationUser: {
        component: () => import('@/components/molecules/sidebar/SidebarNavigationUser.vue'),
        id: ESidebarComponentId.SidebarNavigationUser,
      },
    };
  },
  computed: {
    unreadThreadsCount(): number {
      return this.$accessor.notifications.messages.unreadThreadsCount;
    },
    userSidebarComponent(): SidebarComponent {
      return this.isAuthenticated ? this.SidebarNavigationUser : this.FormLogin;
    },
    navLinkComponent(): string {
      return this.isAuthenticated ? 'BaseLink' : 'SidebarToggle';
    },
  },
});
