import * as Sentry from '@sentry/core';
import { SeverityLevel, User } from '@sentry/types';
import { AxiosError } from 'axios';

import { AppEnvironment, EAppEnvironment } from '@/domain/core/Environment.enum';
import { UserPublic } from '@/domain/user/types';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { accessorType } from '@/store';
import { _isObjectEmpty } from '@/utilities/objects/isObjectEmpty';

export interface ErrorMonitorInterface {
  report(err: unknown, errorLevel?: SeverityLevel): void;
}

export default class ErrorMonitor implements ErrorMonitorInterface {
  readonly #appEnvironment: AppEnvironment;
  readonly #sentry: typeof Sentry;
  readonly #store: typeof accessorType;

  constructor(sentry: typeof Sentry, appEnvironment: AppEnvironment, store: typeof accessorType) {
    this.#appEnvironment = appEnvironment;
    this.#sentry = sentry;
    this.#store = store;
  }

  report(err: unknown, errorLevel?: SeverityLevel): void {
    const statusCode = (err as AxiosError).response?.status || (err as HttpResponse)?.statusCode || null;
    const is400HttpError = !!statusCode && statusCode >= 400 && statusCode <= 499;

    if (is400HttpError) {
      return;
    }

    this.#logError(err);

    if (!_isObjectEmpty(err)) {
      this.#sendErrorToSentry(err, errorLevel);
    }
  }

  #getUserIdFromStore(): UserPublic['id'] | null {
    return this.#store?.user?.id || null;
  }

  #logError(err: any): void {
    const { Development, Staging, Production } = EAppEnvironment;

    if (this.#appEnvironment === Development) {
      // eslint-disable-next-line no-console
      console.log(err);
    } else if (process.server && [Production, Staging].includes(this.#appEnvironment)) {
      process.stderr.write(JSON.stringify(err, this.#replaceCircularReferences()));
    }
  }

  #sendErrorToSentry(err: unknown, errorLevel?: SeverityLevel): void {
    const sentryUser: User = { ip_address: '{{auto}}' };
    const userId = this.#getUserIdFromStore();

    if (userId) {
      sentryUser.id = userId;
    }

    this.#sentry.setUser(sentryUser);

    if (err instanceof Error) {
      this.#sentry.captureException(err, { level: errorLevel }); // Error object contains stack trace
    } else {
      this.#sentry.captureMessage(JSON.stringify(err, this.#replaceCircularReferences()), { level: errorLevel });
    }
  }

  #replaceCircularReferences(): (_: unknown, value: unknown) => string | unknown | void {
    const seen = new WeakSet();

    return (_, value): string | unknown => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return '{CIRCULAR_REFERENCE_NOT_SERIALIZED}';
        }

        seen.add(value);
      }

      return value;
    };
  };
}
