import { actionTree, mutationTree } from 'typed-vuex';

import { EFeatureFlags, FeatureFlags, LaunchDarklyPayload } from '@/infrastructure/externalServices/launchDarkly/types';
import { accessorType } from '@/store';

export interface State {
  list: FeatureFlags;
  rawData: LaunchDarklyPayload | null;
};

export const state = (): State => {
  const flagsWithDefaultValues = Object.fromEntries(
    Object.values(EFeatureFlags)
      .map((value) => ([value, false])),
  ) as FeatureFlags;

  return {
    list: flagsWithDefaultValues,
    rawData: null,
  };
};

export const mutations = mutationTree(state, {
  SET_STATUSES: (state, payload: Partial<FeatureFlags>): void => {
    const filterOutUnusedFlags = (flags: FeatureFlags): FeatureFlags => Object.fromEntries(
      Object.entries(flags)
        // @ts-expect-error - TS can't infer the type of the key 🤡
        .map(([key]) => ([key, payload?.[key] || false])),
    ) as FeatureFlags;

    state.list = filterOutUnusedFlags(state.list);
  },
  SET_RAW_DATA: (state, payload: LaunchDarklyPayload): void => {
    state.rawData = payload;
  },
});

export const actions = actionTree({ state, mutations }, {
  // Compute the feature flags statuses based on the raw data and the potential user's email.
  computeFlagsValue(): void {
    const accessor: typeof accessorType = this.app.$accessor;

    if (accessor.featureFlags.rawData) {
      const statuses = this.$services.launchDarklyClient.getFeatureFlagStatuses(accessor.featureFlags.rawData, accessor.user?.email);

      accessor.featureFlags.SET_STATUSES(statuses);
    }
  },
  async getRawData(): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;

    try {
      const featureFlagsPayload = await this.$services.launchDarklyClient.getFeatureFlagsRawData();

      accessor.featureFlags.SET_RAW_DATA(featureFlagsPayload);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
