import { ValueOf } from 'type-fest';

export const ENodeEnvironment = {
  Development: 'development',
  Production: 'production',
} as const;

export const EAppEnvironment = {
  Development: 'dev',
  Production: 'prod',
  Staging: 'staging',
} as const;

export type AppEnvironment = ValueOf<typeof EAppEnvironment>;
