import { ValueOf } from 'type-fest';

import { ECountryCodeISO31661 } from './Country.enum';

export const ELanguage = {
  EN: 'en',
  FR: 'fr',
} as const;

export type Language = ValueOf<typeof ELanguage>;

export const ELanguageName = {
  EN: 'English',
  FR: 'Français',
} as const;

export const LanguageISO = {
  EN: {
    GB: `${ELanguage.EN}-${ECountryCodeISO31661.GB}`,
    NL: `${ELanguage.EN}-${ECountryCodeISO31661.NL}`,
    US: `${ELanguage.EN}-${ECountryCodeISO31661.US}`,
  },
  FR: {
    FR: `${ELanguage.FR}-${ECountryCodeISO31661.FR}`,
  },
} as const;
