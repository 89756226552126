import { ValueOf } from 'type-fest';

import { Image } from '@/domain/core/image/types';
import { Category } from '@/domain/product/types';
import { Selection } from '@/domain/selection/types';

export const EStaticAsset = {
  HandmadeCategories: 'handmade-categories',
  PopularSearches: 'popular-searches',
} as const;

export interface HandmadeCategory {
  imageId: string;
  title: string;
  url: string;
}

export interface SearchQuery {
  imageId: string;
  query: string;
}

export interface MerchandisingBlock {
  image: Image;
  title: string;
  url: string;
}

export const EMenuItemTargetType = {
  Category: 'category',
  Custom: 'custom',
  Selection: 'selection',
} as const;

type TargetType<T = ValueOf<typeof EMenuItemTargetType>> = {
  href?: string; // NOTE: Computed for the client only (using 'target' content). Links should be computed by the back-end...
  type: T;
};

type TargetCategory = TargetType<'category'>
  & Pick<Category, 'slugsTree'>;

type TargetCustom = TargetType<'custom'>
  & { value: string };

type TargetSelection = TargetType<'selection'>
  & Pick<Selection, 'reference' | 'slug'>;

export type MenuItemTarget = TargetCategory | TargetCustom | TargetSelection;

export interface MenuItem {
  children?: MenuItem[];
  createdAt: string;
  id: string;
  image: Image;
  isNew: boolean;
  isPromotional: boolean;
  merchandisingBlocks: MerchandisingBlock[];
  parentId: string | null;
  target: MenuItemTarget;
  title: string;
  updatedAt: string;
}

export type MenuItemSeeAll = Pick<MenuItem, 'title' | 'target'>;
