import { ValueOf } from 'type-fest';

import { AppEnvironment, EAppEnvironment } from '@/domain/core/Environment.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { HttpCacheClientInterface } from '@/infrastructure/core/http/httpCacheClient';
import {
  ELaunchDarklyEnvironment,
  FeatureFlags,
  LaunchDarklyApiItem,
  LaunchDarklyPayload,
} from '@/infrastructure/externalServices/launchDarkly/types';

export default class LaunchDarklyClient {
  readonly #httpCache: HttpCacheClientInterface;
  readonly #accessToken: string;
  readonly #appEnvironment: string;
  readonly #launchDarklyApiBaseUrl = 'https://app.launchdarkly.com/api/v2';
  readonly #launchDarklyFeatureFlagsCacheKey = 'launch-darkly-flags-visitor';

  constructor(httpCache: HttpCacheClientInterface, accessToken: string, appEnvironment: AppEnvironment) {
    this.#httpCache = httpCache;
    this.#accessToken = accessToken;
    this.#appEnvironment = appEnvironment;
  }

  #getLaunchDarklyEnvironment(): ValueOf<typeof ELaunchDarklyEnvironment> {
    const { Production, Staging } = ELaunchDarklyEnvironment;

    return this.#appEnvironment === EAppEnvironment.Production ? Production : Staging;
  }

  async getFeatureFlagsRawData(): Promise<LaunchDarklyPayload> {
    const launchDarklyEnvironment = this.#getLaunchDarklyEnvironment();
    const { content } = await this.#httpCache.getCachedResponse<LaunchDarklyPayload>(this.#launchDarklyFeatureFlagsCacheKey,
      {
        path: `${this.#launchDarklyApiBaseUrl}/flags/default`,
        method: EHttpMethod.Get,
        headers: {
          [EHttpHeader.Authorization]: this.#accessToken,
        },
        queryParams: {
          env: launchDarklyEnvironment,
          summary: '0', // https://apidocs.launchdarkly.com/tag/Feature-flags#operation/getFeatureFlags!in=query&path=summary&t=request
        },
      },
      {
        EX: 3600,
      },
    );

    return content;
  }

  getFeatureFlagStatuses(payload: LaunchDarklyPayload, userEmail: string | null = null): Partial<FeatureFlags> {
    const featureFlagStatuses: Partial<FeatureFlags> = {};
    const launchDarklyEnvironment = this.#getLaunchDarklyEnvironment();
    const launchDarklyApiItems: LaunchDarklyApiItem[] = payload?.items || [];

    for (const featureFlag of launchDarklyApiItems) {
      if (featureFlag?.key) {
        const featureFlagProps = featureFlag?.environments?.[launchDarklyEnvironment];

        let isFeatureFlagOn = featureFlagProps?.on || false;

        if (userEmail) {
          for (const target of featureFlagProps?.targets) {
            const isIndividualVariation = target.contextKind === 'user';
            const isUserTargeted = target.values.includes(userEmail);
            const isVariationOn = target.variation !== featureFlagProps.offVariation;
            const isFeatureFlagOnForSpecificUser = isIndividualVariation && isUserTargeted && isVariationOn;

            if (isUserTargeted) {
              isFeatureFlagOn = isFeatureFlagOnForSpecificUser;
            }
          }
        }

        featureFlagStatuses[featureFlag.key] = isFeatureFlagOn;
      }
    }

    return featureFlagStatuses;
  }
}
