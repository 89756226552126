var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"appear":"","name":"fade"}},[(_vm.isDisplayable)?_c('span',{staticClass:"absolute inline-block size-4 subpixel-antialiased text-[8px] leading-[14px] text-center border border-white rounded-2xl",class:{
      '-top-1.5 -right-1.5': !_vm.positionModifiers,
      [_vm.positionModifiers]: _vm.positionModifiers,
      'text-accent-font bg-accent': !_vm.colorClasses,
      [_vm.colorClasses]: _vm.colorClasses,

    },attrs:{"data-testid":"notification-dot"}},[_vm._v("\n    "+_vm._s(_vm.fmtCount)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }