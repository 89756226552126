import VueRouter, { Location } from 'vue-router';

import { EFacetNameRefinementList, FacetNameRefinementList } from '@/domain/core/algolia/types';
import { Language } from '@/domain/core/Language.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { Category, CategoryLevelDepth, ECategoryLevelDepth, LegacyCategory } from '@/domain/product/types';
import { Link } from '@/infrastructure/core/components/link/Link.interface';

export interface CategoryRouterInterface {
  convertSlugsTreeToRouterParameters(slugsTree: Category['slugsTree']): Location['params'];
  getBreadcrumbs(category: LegacyCategory, breadcrumbs?: Link[]): Link[];
  getBreadcrumbsFromCategories(categories: Category[]): Link[];
  getPath(category: LegacyCategory, locale?: Language): string | null;
}

export default class CategoryRouter implements CategoryRouterInterface {
  #categoryDepthToCategoryParam: Record<CategoryLevelDepth, FacetNameRefinementList> = {
    [ECategoryLevelDepth.Level1]: EFacetNameRefinementList.CategoryLevel1,
    [ECategoryLevelDepth.Level2]: EFacetNameRefinementList.CategoryLevel2,
    [ECategoryLevelDepth.Level3]: EFacetNameRefinementList.CategoryLevel3,
  };

  #router: VueRouter;

  constructor(router: VueRouter) {
    this.#router = router;
  }

  convertSlugsTreeToRouterParameters(slugsTree: Category['slugsTree']): Location['params'] {
    const params = slugsTree?.map((slug, index) => {
      const level = index + 1;
      const paramName = this.#categoryDepthToCategoryParam[level as CategoryLevelDepth];

      return { [paramName]: slug };
    });

    return Object.assign({}, ...params);
  }

  getBreadcrumbs(category: LegacyCategory, breadcrumbs: Link[] = []): Link[] {
    const localBreadcrumbs = [...breadcrumbs];

    if (!category?.name || !category?.slug) {
      return localBreadcrumbs;
    }

    localBreadcrumbs.unshift({
      label: category.name,
      props: {
        href: this.getPath(category),
        to: {
          name: ERouteName.Category,
          params: this.#getRouteParams(category),
        },
      },
    });

    return category.parent ? this.getBreadcrumbs(category.parent, localBreadcrumbs) : localBreadcrumbs;
  }

  getBreadcrumbsFromCategories(categories: Category[]): Link[] {
    return categories.map((category) => {
      const categoryLocation = {
        name: ERouteName.Category,
        params: this.convertSlugsTreeToRouterParameters(category.slugsTree),
      };

      return {
        label: category.name,
        props: {
          href: this.#router.resolve(categoryLocation)?.href || null,
          to: categoryLocation,
        },
      };
    });
  }

  getPath(category: LegacyCategory, locale?: Language): string | null {
    const categoryLocation = {
      name: ERouteName.Category,
      params: this.#getRouteParams(category, {}, locale),
    };

    return this.#router.resolve(categoryLocation)?.href || null;
  }

  #getRouteParams(
    category: LegacyCategory,
    params: Record<string, string> = {},
    locale: Language | null = null,
  ): Record<string, string> {
    const localParams = { ...params };

    if (category.level === null || category.slug === null || (locale && category?.i18nSlugs?.[locale] === undefined)) {
      return {};
    }

    const paramKey = this.#categoryDepthToCategoryParam[category.level];

    localParams[paramKey] = locale ? category?.i18nSlugs?.[locale] as string : category.slug;

    return category.parent ? this.#getRouteParams(category.parent, localParams, locale) : localParams;
  }
}
