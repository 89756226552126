
import Vue, { PropType } from 'vue';

import { EProductStatus, ProductCore } from '@/domain/product/types';

export default Vue.extend({
  name: 'ProductPrice',
  props: {
    // TODO: Needs to be standardized once in the catalog (e.g. renamed to "price" | "prices")
    product: {
      type: Object as PropType<ProductCore>,
      required: true,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
    shouldHideSpecialBadges: {
      type: Boolean,
      default: false,
    },
    shouldEnableMicrodata: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasProPrice(): boolean {
      return this.isUserPro
        && !!this.product?.pricing?.pro?.isActive;
    },
    isSoldOut(): boolean {
      return this.product.status === EProductStatus.SoldOut;
    },
    specialPrice(): number | null {
      const pricing = this?.product?.pricing;
      let specialPrice = null;

      if (this.hasProPrice) {
        specialPrice = pricing?.pro?.price || null;
      } else if (pricing?.specialPrice) {
        specialPrice = pricing.specialPrice;
      }

      if (specialPrice === this.price) {
        specialPrice = null;
      }

      return specialPrice;
    },
    price(): number | null {
      return this.product.pricing?.price || null;
    },
    shouldDisplayHandmadeBadge(): boolean {
      return !!this.product?.isHandmade
        && !this.hasProPrice
        && !this.specialPrice
        && !this.shouldHideSpecialBadges;
    },
    shouldDisplayNegotiableBadge(): boolean {
      return !!this.product.pricing?.isNegotiable
        && !this.hasProPrice
        && !this.specialPrice
        && !this.shouldHideSpecialBadges;
    },
    shouldDisplayPricing(): boolean {
      return !!this.product?.pricing?.price && !this.isSoldOut;
    },
  },
});
