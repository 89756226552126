
import { ArrayValues } from 'type-fest';
import Vue from 'vue';

import IcFlagFr from '@/assets/svg/flags/fr.svg?inline';
import IcFlagGb from '@/assets/svg/flags/gb.svg?inline';
import { ELanguage, ELanguageName } from '@/domain/core/Language.enum';
import { CatalogRoutesName } from '@/domain/core/Routes.enum';
import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';
import { _stripHrefFromAlgoliaInternationalizedQueryParameters } from '@/utilities/algolia/stripHrefFromAlgoliaInternationalizedQueryParameters';

export default Vue.extend({
  name: 'SelectLocale',
  components: {
    IcFlagFr,
    IcFlagGb,
  },
  data() {
    return {
      ELanguage: Object.freeze(ELanguage),
    };
  },
  computed: {
    selectedLocale: {
      get() {
        return this.$i18n.locale;
      },
      set(newLocaleCode: string) {
        this.$i18n.setLocale(newLocaleCode);

        // NOTE: Can't find a place to hold this logic specific to catalog pages and Algolia query parameters. I hate to put it there. Please move it if you have a better idea !
        const isCatalogPage = CatalogRoutesName.includes(this.$route.name as ArrayValues<typeof CatalogRoutesName>);

        if (isCatalogPage) {
          const href = _stripHrefFromAlgoliaInternationalizedQueryParameters(window.location.href);

          if (href !== window.location.href) {
            window.history.pushState({}, document.title, href);
          }
        }

        window.location.reload();
      },
    },
    localesOptions(): BaseSelectOption[] {
      return this.$i18n.localeCodes?.map((localeCode) => ({
        label: ELanguageName[localeCode.toUpperCase() as keyof typeof ELanguageName],
        value: localeCode,
      }));
    },
  },
});
