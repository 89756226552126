
import Vue, { PropType } from 'vue';

import { EMenuItemTargetType, MenuItem } from '@/domain/cms/types';
import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

const hoverDelayInMs = 200;

export default Vue.extend({
  name: 'NavigationMain',
  props: {
    navigation: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      isMouseOut: false,
      hoverDelayTimer: null as ReturnType<typeof setTimeout> | null,
    };
  },
  computed: {
    // NOTE: obfuscate every link except the current category (SEO).
    obfuscatedNavigation(): MenuItem[] {
      let obfuscatedNavigation = [...this.navigation];

      const categorySlugLevel1 = this.$route?.params?.[EFacetNameRefinementList.CategoryLevel1];

      if (categorySlugLevel1 && this.$accessor?.featureFlags?.list?.[EFeatureFlags.SeoObfuscateMainMenuLinks]) {
        const obfuscateNavigationHrefs = (items: MenuItem[]): MenuItem[] => items.map((item) => {
          let fmtItem = { ...item };
          const currentItemIsNotFromCurrentCategory = item?.target?.type === EMenuItemTargetType.Category
            && item?.target?.slugsTree?.[0] !== categorySlugLevel1;

          if (currentItemIsNotFromCurrentCategory) {
            fmtItem = {
              ...item,
              target: {
                ...item.target,
                href: this.$services?.navigation?.obfuscateLink(item?.target?.href),
              },
              children: item.children ? obfuscateNavigationHrefs(item.children) : [],
            };
          }

          return fmtItem;
        });

        obfuscatedNavigation = obfuscateNavigationHrefs([...obfuscatedNavigation]);
      }

      return obfuscatedNavigation;
    },
  },
  methods: {
    clearTimer() {
      if (this.hoverDelayTimer) {
        clearTimeout(this.hoverDelayTimer);
      }
    },
    handleActiveMenu(index: number) {
      this.clearTimer();
      this.hoverDelayTimer = setTimeout(() => {
        this.activeIndex = index;
        this.isMouseOut = false;
      }, hoverDelayInMs);
    },
    handleMouseLeave(): void {
      this.clearTimer();
      this.isMouseOut = true;
    },
  },
});
