import type { CamelCasedPropertiesDeep, ValueOf } from 'type-fest';
import { TranslateResult } from 'vue-i18n';

import { CurrencyCode } from '@/domain/pricing/types';

export const EProviderType = {
  BySelency: 'by_selency',
  Cocolis: 'cocolis',
  Colissimo: 'colissimo',
  ColissimoPsa: 'colissimo_psa',
  Custom: 'custom',
  MondialRelay: 'mondial_relay',
  PersonalDelivery: 'personal_delivery',
  WithdrawalAtHome: 'withdrawal_at_home',
} as const;

export type ProviderType = ValueOf<typeof EProviderType>;

export const ELegacyProviderType = {
  BrocanteLab: 'BROCANTE_LAB',
  Chronopost: 'CHRONOPOST',
  Cocolis: 'COCOLIS',
  Colissimo: 'COLISSIMO',
  ColissimoPSA: 'COLISSIMO_PSA',
  Custom: 'CUSTOM',
  DHL: 'DHL',
  DPD: 'DPD',
  FedEx: 'FEDEX',
  MondialRelay: 'MONDIAL_RELAY',
  Other: 'OTHER',
  PersonalDelivery: 'PERSONAL_DELIVERY',
  Quotation: 'QUOTATION',
  RelaisColis: 'RELAIS_COLIS',
  Retail: 'RETAIL',
  UPS: 'UPS',
  WithdrawalAtHome: 'WITHDRAWAL_AT_HOME',
} as const;

export type LegacyProviderType = ValueOf<typeof ELegacyProviderType>;

export const ELegacyProviderDelay = {
  Express: 'express',
  Standard: 'standard',
} as const;

interface Package {
  depth: number | null;
  height: number | null;
  weight: number | null;
  width: number | null;
}

interface GMapsAddress {
  country: string | null;
  place_id: string | null;
  type: string | null;
  value: string | null;
}

export interface LegacyDeliveryLocation {
  city: string;
  country: string;
  label: string;
  postcode: string;
}

export interface LegacyDeliveryOfferSnakeCase {
  alternative_to: string | null;
  auto_price: boolean | null;
  booster: {
    booster_type: string;
    enabled: boolean;
    price: {
      currency: CurrencyCode;
      value: number;
    };
    quotation_id: string | null;
  } | null;
  created_at: string | null;
  custom_label: string | null;
  delay: ValueOf<typeof ELegacyProviderDelay>| null;
  delivery_option: {
    created_at: string | null;
    delivery_offers: (LegacyDeliveryOfferSnakeCase | null)[];
    id: string | null;
    provider_type: LegacyProviderType;
    updated_at: string | null;
  } | null;
  delivery_option_set_id: string | null;
  delivery_services: any[];
  destination_continent: string | null;
  destination_country: string | null;
  destination_department: string | null;
  destination_region: string | null;
  destination_superzone_id: string | null;
  destination_zipcode: string | null;
  generated: boolean;
  gmaps_destination_components: GMapsAddress[];
  id: string | null;
  insurance: boolean | null;
  max_distance: string | null;
  min_distance: string | null;
  packages: Package[];
  packing_cost: {
    currency: CurrencyCode;
    value: number;
  } | null;
  packlink_price: string | null;
  price: {
    currency: CurrencyCode;
    value: number;
  };
  provider_type: LegacyProviderType;
  quotation_created_at: string | null;
  selected_delivery_services: any[];
  status: string | null;
  title: string | null;
  updated_at: string | null;
  user_id: string | null;
}

export type LegacyDeliveryOffer = CamelCasedPropertiesDeep<LegacyDeliveryOfferSnakeCase>;

export interface DeliveryOfferCore {
  delay: TranslateResult | null;
  icon: TranslateResult | null;
  id: string | null;
  price: {
    currency: CurrencyCode;
    value: number;
  };
  providerType: LegacyProviderType;
  sellerParticipation: LegacyDeliveryOffer['booster'] | null;
  specialPrice: number | null;
  title: TranslateResult | string;
}

interface PickupPointOpeningTimes {
  friday: string[];
  monday: string[];
  saturday: string[];
  sunday: string[];
  thursday: string[];
  tuesday: string[];
  wednesday: string[];
}

export interface PickupPoint {
  addressName: string;
  externalId: string | null;
  latitude: string;
  longitude: string;
  name: string;
  openingTimes: PickupPointOpeningTimes;
  phoneNumber: string;
  provider: ProviderType;
}

export type PickupPointId = PickupPoint['externalId'];
