
import Vue, { PropType } from 'vue';

import { EInputType, InputType } from '@/domain/core/InputType.enum';

export default Vue.extend({
  name: 'InputText',
  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },
    cssSpacingClasses: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: 'input-text',
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputId: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    minlength: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<InputType>,
      default: EInputType.Text,
      validator: (value: InputType): boolean => {
        return Object.values(EInputType).includes(value);
      },
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    modelValue: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('update:value', value);
      },
    },
  },
});
