import { legacyWishlistAdapter, legacyWishlistBoardAdapter } from '@/adapters/wishlist.adapter';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { PaginatedList } from '@/domain/core/api/types';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { Product } from '@/domain/product/types';
import { UserPrivate } from '@/domain/user/types';
import {
  LegacyWishlist,
  LegacyWishlistBoard,
  Wishlist,
  WishlistBoard,
  WishlistProduct,
  WishlistRequestParamsAddProduct,
  WishlistRequestParamsCreateBoard,
  WishlistRequestParamsGetBoard,
  WishlistRequestParamsRemoveProduct,
} from '@/domain/wishlist/types';
import { WishlistRepositoryInterface } from '@/domain/wishlist/wishlistRepository.interface';
import { transformListPaginated } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const resourcePath = '/wishlists';
const boardsResourcePath = '/boards';

export default class WishlistRepository implements WishlistRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #legacyBasePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, legacyBasePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#legacyBasePath = legacyBasePath;
    this.#security = security;
  }

  async addProduct({ boardId, productId, wishlistId }: WishlistRequestParamsAddProduct): Promise<Wishlist> {
    const token = this.#security.getToken();
    let payload: {
      boardId?: WishlistBoard['id'];
      mv?: boolean;
      product: WishlistProduct['id'];
    } = { product: productId };

    if (boardId) {
      payload = {
        ...payload,
        boardId,
        mv: true,
      };
    }

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#legacyBasePath}${resourcePath}/${wishlistId}/products`,
      payload,
    });

    return legacyWishlistAdapter(content.content);
  }

  async createBoard({ boardName, wishlistId }: WishlistRequestParamsCreateBoard): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#legacyBasePath}${resourcePath}/${wishlistId}${boardsResourcePath}`,
      payload: {
        board_name: boardName,
      },
    });

    return legacyWishlistAdapter(content.content);
  }

  async deleteBoard({ boardId, wishlistId }: WishlistRequestParamsGetBoard): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#legacyBasePath}${resourcePath}/${wishlistId}${boardsResourcePath}`,
      queryParams: { boardId },
    });

    return legacyWishlistAdapter(content.content);
  }

  async createWishlist(): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${resourcePath}`,
    });

    return legacyWishlistAdapter(content.content);
  }

  async getBoard({ boardId, wishlistId }: WishlistRequestParamsGetBoard): Promise<WishlistBoard> {
    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlistBoard>>({
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}${resourcePath}/${wishlistId}${boardsResourcePath}/${boardId}`,
    });

    return legacyWishlistBoardAdapter(content?.content);
  }

  async getBoardProducts(
    { boardId, wishlistId }: WishlistRequestParamsGetBoard,
    page: number | null = null,
    limit = 64,
  ): Promise<PaginatedList<Product>> {
    const { content } = await this.#httpService.request<ApiBase<Product>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${wishlistId}${boardsResourcePath}/${boardId}/products`,
      queryParams: {
        limit,
        page,
      },
    });

    return transformListPaginated(content);
  }

  async getWishlistByUserId(userId: UserPrivate['legacyId']): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}${resourcePath}`,
      queryParams: { user: `${userId}` },
    });

    return legacyWishlistAdapter(content.content);
  }

  async removeProduct({ productId, wishlistId }: WishlistRequestParamsRemoveProduct): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#legacyBasePath}${resourcePath}/${wishlistId}/products`,
      queryParams: { product: productId },
    });

    return legacyWishlistAdapter(content.content);
  }
}
