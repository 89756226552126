import { BaseCarouselOptions } from '@/infrastructure/core/components/carousel/BaseCarousel.interface';

// NOTE: Values are based on tailwind's config but resolving it according to the documentation requirements should be avoided for performance reasons.
// See https://tailwindcss.com/docs/configuration#referencing-in-java-script
export const ETailwindScreensWidth = {
  '2xl': 1536,
  lg: 1024,
  md: 768,
  sm: 640,
  xl: 1280,
  xs: 375,
} as const;

export const defaultCarouselOptions: BaseCarouselOptions = Object.freeze({
  showArrows: true,
  slidesPerPage: 1,
  responsive: [
    {
      minWidth: ETailwindScreensWidth.sm,
      slidesPerPage: 2,
    },
    {
      minWidth: ETailwindScreensWidth.lg,
      slidesPerPage: 3,
    },
  ],
});

export const galleryProductCarouselOptions: BaseCarouselOptions = Object.freeze({
  showArrows: true,
  loop: true,
  slidesPerPage: 1,
  responsive: [],
});

export const productCarouselOptions: BaseCarouselOptions = Object.freeze({
  gutter: 16,
  slidesPerPage: 2,
  responsive: [
    {
      minWidth: ETailwindScreensWidth.sm,
      slidesPerPage: 2,
      peekRight: 60,
    },
    {
      minWidth: ETailwindScreensWidth.md,
      slidesPerPage: 3,
      peekRight: 60,
    },
    {
      minWidth: ETailwindScreensWidth.lg,
      slidesPerPage: 5,
      peekRight: 0,
    },
  ],
});
