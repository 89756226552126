import {
  EAlgoliaCoreSearchCriteria,
  EFacetNameRefinementList as EFacetList,
  HierarchicalMenuCategoriesSearchCriteria,
} from '@/domain/core/algolia/types';

/**
 * Strip URL from Algolia internationalized query parameters.
 * @param href The URL to strip.
 * @returns The URL without Algolia's internationalized query parameters.
 */
export const _stripHrefFromAlgoliaInternationalizedQueryParameters = (href: Location['href']): URL['href'] => {
  const internationalizedQueryParameters = [
    EAlgoliaCoreSearchCriteria.SortBy,
    EFacetList.CategoryLevel1,
    EFacetList.CategoryLevel2,
    EFacetList.CategoryLevel3,
    EFacetList.ColorFacet,
    EFacetList.MaterialTitle,
    EFacetList.StyleTitle,
    HierarchicalMenuCategoriesSearchCriteria,
  ];
  const url = new URL(href);

  for (const queryParameter of internationalizedQueryParameters) {
    url.searchParams.delete(queryParameter);
  }

  return url.href;
};
