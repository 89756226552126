
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import IcArrowRightLong from '@/assets/svg/ic_arrow_right_long.svg?inline';
import IcCrossSmall from '@/assets/svg/ic_cross_small.svg?inline';
import { ETailwindScreensWidth, productCarouselOptions } from '@/config/plugins/SsrCarouselOptions';
import { QuerySuggestionDocument } from '@/domain/core/algolia/types';
import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { ProductCore } from '@/domain/product/types';
import { Search } from '@/domain/search/types';
import { BaseCarouselOptions } from '@/infrastructure/core/components/carousel/BaseCarousel.interface';
import {
  EGtmEcommerceListName,
  EGtmEventSearch,
  EGtmToggleSearchAlertLocation,
  EGtmToggleSearchAlertStatus,
} from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'InstantSearchAutocompleteSuggestions',
  components: {
    IcArrowRightLong,
    IcCrossSmall,
  },
  props: {
    suggestions: {
      type: Array as PropType<QuerySuggestionDocument[]>,
      required: true,
    },
    isSearchQueryPopulated: {
      type: Boolean,
      required: false,
    },
    products: {
      type: Array as PropType<ProductCore[]>,
      required: true,
    },
    searchPageHref: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      maxDisplayedSearches: 3,
      savedSearches: [] as Search[],
      recentSearches: [] as string[],
      trackingId: EGtmEcommerceListName.SearchPreview,
    };
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    if (this.shouldFetchSearchList) {
      await this.fetchSearchList();
    }
  },
  computed: {
    carouselOptions(): BaseCarouselOptions {
      return {
        ...productCarouselOptions,
        responsive: productCarouselOptions.responsive?.map((item) => {
          const localItem = { ...item };

          if (localItem.minWidth === ETailwindScreensWidth.lg) {
            localItem.slidesPerPage = 6;
          }

          return localItem;
        }),
      };
    },
    shouldDisplaySearchList(): boolean {
      return this.savedSearches.length > 0 && ((!this.isSearchQueryPopulated && this.$device.isMobileOrTablet) || this.$device.isDesktop);
    },
    shouldDisplayRecentSearchList(): boolean {
      return this.recentSearches.length > 0
        && ((!this.isSearchQueryPopulated && this.$device.isMobileOrTablet) || this.$device.isDesktop);
    },
    shouldDisplayProductsSuggestions(): boolean {
      return this.products.length > 0
        && this.isSearchQueryPopulated;
    },
    shouldFetchSearchList(): boolean {
      return this.$accessor.user.authenticated;
    },
    suggestionsTitle(): TranslateResult {
      let title = this.$t('header.search.searchSuggestions');

      if (!this.isSearchQueryPopulated) {
        title = this.$t('header.search.popularSearches');
      }

      return title;
    },
  },
  mounted() {
    this.recentSearches = JSON.parse(localStorage.getItem(ELocalStorageItem.SearchListRecent) || '[]');
  },
  methods: {
    handleClickSuggestion(query: string): void {
      this.$emit('click-suggestion', query);
    },
    hideSuggestions(): void {
      this.$emit('hide-suggestions');
    },
    async fetchSearchList(): Promise<void> {
      try {
        this.savedSearches = await this.$repositories.search.getList(this.$accessor.user.id, this.maxDisplayedSearches, true);
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      }
    },
    async toggleAlert(search: Search): Promise<void> {
      try {
        await this.$repositories.search.update(search.searchId, {
          shouldSendEmail: !search.shouldSendEmail,
          shouldSendNotification: !search.shouldSendNotification,
        });

        const index = this.savedSearches.findIndex((savedSearch: Search) => savedSearch.searchId === search.searchId);

        if (index !== -1) {
          this.savedSearches[index].shouldSendEmail = !this.savedSearches[index].shouldSendEmail;
          this.savedSearches[index].shouldSendNotification = !this.savedSearches[index].shouldSendNotification;
        }

        this.handleTracking(this.savedSearches[index]);
      } catch (err) {
        this.$errorMonitor.report(err, 'error');
      }
    },
    handleTracking(search: Search): void {
      this.$gtm.push({
        event: EGtmEventSearch.ToggleAlert,
        location: EGtmToggleSearchAlertLocation.SearchBar,
        search_id: search.searchId,
        status: this.getSearchAlertStatus(search),
      });
    },
    getSearchAlertStatus({ shouldSendEmail, shouldSendNotification }: Search): ValueOf<typeof EGtmToggleSearchAlertStatus> {
      return shouldSendEmail || shouldSendNotification ? EGtmToggleSearchAlertStatus.Enabled : EGtmToggleSearchAlertStatus.Disabled;
    },
  },
});
