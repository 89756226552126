
import Vue from 'vue';

import { QuerySuggestionDocument } from '@/domain/core/algolia/types';
import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { ProductCore } from '@/domain/product/types';
import { EGtmEventSearch } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { SearchEvent } from '@/infrastructure/externalServices/gtm/search/SearchEventPayload.interface';
import { _removeDiacritics } from '@/utilities/strings/removeDiacritics';

export default Vue.extend({
  name: 'InstantSearchAutocomplete',
  data() {
    return {
      isInputFocused: false,
      searchQuery: '',
      suggestions: [] as QuerySuggestionDocument[],
      products: [] as ProductCore[],
    };
  },
  computed: {
    hasSuggestions(): boolean {
      return !!this.suggestions?.length;
    },
    isSearchQueryPopulated(): boolean {
      return !!this.searchQuery;
    },
    searchPageHref(): string {
      return this.$router.resolve({
        name: ERouteName.Search,
        query: {
          query: this.searchQuery || undefined, // NOTE: it needs to fallback to 'undefined' when falsy (empty string) to avoid having an empty query param.
        },
      }).href;
    },
  },
  mounted() {
    this.getSearchQueryFromUrl();

    window.addEventListener('click', this.clickOutsideEventHandler);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutsideEventHandler);
  },
  methods: {
    addSearchQueryToLocalStorage(rawQuery: string): void {
      if (!rawQuery) {
        return;
      }

      const searchQuery = rawQuery.toLowerCase();

      const maxQueriesAllowed = 3;

      const rawQueries = localStorage.getItem(ELocalStorageItem.SearchListRecent);
      const parsedQueries: string[] = rawQueries ? JSON.parse(rawQueries) : [];
      const filteredQueries = parsedQueries.filter((item) => item !== searchQuery && !!item); // Clear out current search query and null values.

      const queries = [
        searchQuery,
        ...filteredQueries,
      ];

      queries.splice(maxQueriesAllowed); // Limit array to max queries allowed above.

      localStorage.setItem(ELocalStorageItem.SearchListRecent, JSON.stringify(queries));
    },
    clickOutsideEventHandler(event: Event): void {
      const { target } = event;

      if (!this.$el.contains(target as HTMLButtonElement)) {
        this.hideSuggestions();
      }
    },
    getSearchQueryFromUrl(): void {
      let currentQuery = this.$route?.query?.query;

      if (this.$route?.name === ERouteName.SearchSeo) {
        currentQuery = this.$route.params?.slug?.replace(/-/g, ' ');
      }

      if (currentQuery && typeof currentQuery === 'string') {
        this.searchQuery = currentQuery;
      }
    },
    handleFocus(): void {
      this.isInputFocused = true;

      if (!this.hasSuggestions) {
        this.searchSuggestions();
      }
    },
    hideSuggestions(): void {
      this.isInputFocused = false;
    },
    handleSubmit(): void {
      const correspondingSuggestion = this.suggestions
        .find(({ query, pageType }) => _removeDiacritics(query.toLowerCase()) === _removeDiacritics(this.searchQuery.toLowerCase())
          && pageType !== 'query');
      let href = this.searchPageHref;

      if (correspondingSuggestion?.url) {
        href = correspondingSuggestion.url;
      }

      this.addSearchQueryToLocalStorage(this.searchQuery);

      this.handleTracking(EGtmEventSearch.Submit, {
        search_term: this.searchQuery,
        url: `${window.location.origin}${this.searchPageHref}`,
      });

      window.location.href = href;
    },
    handleTracking(event: string, payload: SearchEvent): void {
      this.$gtm.push({
        event,
        ...payload,
      });
    },
    async searchSuggestions(): Promise<void> {
      try {
        const [suggestions, products] = await Promise.all([
          this.$repositories.catalogProduct.getQuerySuggestions(this.searchQuery),
          this.$repositories.catalogProduct.getProductsSuggestions(this.searchQuery),
        ]);

        this.suggestions = suggestions?.documents || [];
        this.products = products;
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      }
    },
  },
});
