import { Plugin } from '@nuxt/types';

import { EAppEnvironment } from '@/domain/core/Environment.enum';
import AddressRepository from '@/infrastructure/address/addressRepository';
import AuthRepository from '@/infrastructure/auth/authRepository';
import CartRepository from '@/infrastructure/cart/cartRepository';
import CmsRepository from '@/infrastructure/cms/cmsRepository';
import { RepositoryTree } from '@/infrastructure/core/repository/RepositoryTree.interface';
import DeliveryRepository from '@/infrastructure/delivery/deliveryRepository';
import EditoRepository from '@/infrastructure/edito/editoRepository';
import ForumRepository from '@/infrastructure/forum/forumRepository';
import OrderRepository from '@/infrastructure/order/orderRepository';
import CurrencyRepository from '@/infrastructure/pricing/currencyRepository';
import CatalogProductRepository from '@/infrastructure/product/catalogProductRepository';
import ProductRepository from '@/infrastructure/product/productRepository';
import ReviewRepository from '@/infrastructure/review/reviewRepository';
import SearchRepository from '@/infrastructure/search/searchRepository';
import SelectionRepository from '@/infrastructure/selection/selectionRepository';
import SeoRepository from '@/infrastructure/seo/seoRepository';
import ShopRepository from '@/infrastructure/shop/shopRepository';
import UserRepository from '@/infrastructure/user/userRepository';
import WishlistRepository from '@/infrastructure/wishlist/wishlistRepository';

const repositoriesPlugin: Plugin = ({ app, $algolia, $config, $externalURLRouter, $http, $httpCache, $security }, inject) => {
  const isDevelopmentAppEnvironment = $config.appEnvironment === EAppEnvironment.Development;
  const selencyInternalAPIURL = $config.repositories.selencyInternal;
  let selencyAPIURL = $config.repositories.selency;

  if (process.server && !isDevelopmentAppEnvironment && !!selencyInternalAPIURL) {
    selencyAPIURL = selencyInternalAPIURL;
  }

  const repositories: RepositoryTree = {
    address: new AddressRepository($http, selencyAPIURL, $config.repositories.address, $security),
    auth: new AuthRepository($http, selencyAPIURL, $config.repositories.auth, app.i18n.locale),
    cart: new CartRepository($http, selencyAPIURL, $config.repositories.checkout, $security, $externalURLRouter),
    catalogProduct: new CatalogProductRepository(
      $algolia,
      app.i18n.locale,
      $config.algolia.indices.products,
      $config.algolia.indices.querySuggestions,
    ),
    cms: new CmsRepository($http, selencyAPIURL),
    currency: new CurrencyRepository($httpCache, selencyAPIURL),
    delivery: new DeliveryRepository($http, selencyAPIURL),
    edito: new EditoRepository($http, $config.repositories.edito, app.i18n.locale),
    forum: new ForumRepository($http, $config.repositories.forum, $security),
    order: new OrderRepository($http, $config.repositories.checkout, $security),
    product: new ProductRepository($http, selencyAPIURL, $security, app.i18n.locale, $config.repositories.product),
    review: new ReviewRepository($http, selencyAPIURL, $security),
    search: new SearchRepository($http, selencyAPIURL, $security),
    selection: new SelectionRepository($http, selencyAPIURL, $security),
    seo: new SeoRepository($http, selencyAPIURL, app.i18n.locale),
    shop: new ShopRepository($http, selencyAPIURL, $security),
    user: new UserRepository($http, selencyAPIURL, $security),
    wishlist: new WishlistRepository($http, selencyAPIURL, $config.repositories.wishlist, $security),
  };

  inject('repositories', repositories);
};

export default repositoriesPlugin;
