
import Vue, { PropType, TransitionProps } from 'vue';

import { MenuItem, MenuItemSeeAll, MerchandisingBlock } from '@/domain/cms/types';
import { SeeAll } from '@/infrastructure/core/components/navigation/SeeAll.interface';
import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

const minNavigationItemsCount = 10;

export default Vue.extend({
  name: 'NavigationMainSubmenu',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    menuItemSeeAll: {
      type: Object as PropType<MenuItemSeeAll>,
      default: () => ({} as MenuItemSeeAll),
    },
    navigation: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    merchandisingBlocks: {
      type: Array as PropType<MerchandisingBlock[]>,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: -1,
      EGtmEventHeader: Object.freeze(EGtmEventHeader),
      focusedNode: null as HTMLAnchorElement | null,
      imagePlaceholderId,
      isMouseOut: false,
      navigationItemsCount: minNavigationItemsCount,
    };
  },
  computed: {
    seeAllLink(): SeeAll {
      return {
        name: this.menuItemSeeAll?.title,
        url: this.menuItemSeeAll?.target?.href || '',
      };
    },
    transitionMode(): TransitionProps['mode'] | undefined {
      return this.level > 1 ? 'out-in' : undefined;
    },
    transitionName(): string {
      return this.level > 1 ? 'fade' : 'fade-menu';
    },
    computedStyle(): Record<string, string> {
      const itemHeight = 24;
      const minMenuHeight = 380;
      const topOffset = 120;
      const calcHeight = this.navigationItemsCount * itemHeight + topOffset;
      const minHeight = `${calcHeight < minMenuHeight ? minMenuHeight : calcHeight}px`;

      return {
        minHeight,
        '--items-count': `${this.navigationItemsCount}`,
      };
    },
  },
  methods: {
    updateSubMenuCount(index: number): void {
      if (!Number.isNaN(index)) {
        const level2 = this.navigation[index];

        this.navigationItemsCount = level2?.children?.length || minNavigationItemsCount;
      }
    },
    updateActiveMenu(index: number): void {
      this.isMouseOut = false;
      this.activeIndex = index;
    },
    resetActiveIndex(): void {
      this.activeIndex = -1;
    },
    handleHover(index: number): void {
      this.updateSubMenuCount(index);
      this.updateActiveMenu(index);
    },
    handleMouseLeave(): void {
      this.isMouseOut = true;
      this.resetActiveIndex();
      this.navigationItemsCount = minNavigationItemsCount;

      if (this.focusedNode) {
        this.focusedNode.blur();
        this.focusedNode = null;
      }
    },
    handleFocus(index: number, $event: Event) {
      $event.stopPropagation();
      this.updateSubMenuCount(index);
      this.updateActiveMenu(index);

      this.focusedNode = $event.target as HTMLAnchorElement;
    },
    trackSubmenuClick(event: string, payload: ClickEventPayload): void {
      this.$gtm.push({
        event,
        ...payload,
      });
    },
  },
});
