
import Vue from 'vue';

import IcCross from '@/assets/svg/ic_cross.svg?inline';
import { EEmitClickEvent } from '@/domain/core/tracking/EventName.enum';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';
import { _formatHTML } from '@/utilities/DOM/formatHTML';

export default Vue.extend({
  name: 'BaseBanner',
  components: {
    IcCross,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    isDismissable: {
      type: Boolean,
      default: false,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBannerDismissed: false,
      fmtContent: '',
    };
  },
  computed: {
    wrapperTag(): string {
      return this.isDismissable ? 'TransitionCollapse' : 'div';
    },
  },
  mounted() {
    this.sanitizeHTMLContent();
  },
  methods: {
    // WARNING: to be able to use SSR, this needs to be processed by the API ASAP.
    sanitizeHTMLContent(): void {
      if (process.client) {
        this.fmtContent = _formatHTML(this.content);
      }
    },
    dismissBanner(): void {
      this.isBannerDismissed = true;
      this.$emit('dismiss');
    },
    handleClickOnContent($event: Event): void {
      const { textContent, nodeName, href } = $event.target as HTMLAnchorElement;

      if (nodeName === 'A') {
        const payload: ClickEventPayload = {
          name: textContent,
          url: href,
        };

        this.$emit(EEmitClickEvent.BannerContent, payload);
      }
    },
  },
});
