import { ValueOf } from 'type-fest';

import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { SeoRepositoryInterface } from '@/domain/seo/seoRepository.interface';
import { ESeoPageContext, SeoPageDetails } from '@/domain/seo/types';
import { transform } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

export default class SeoRepository implements SeoRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #i18nLocale: string;

  constructor(httpService: HttpService, basePath: string, i18nLocale: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#i18nLocale = i18nLocale;
  }

  async getPageDetails(pageContext: ValueOf<typeof ESeoPageContext>, slug: string): Promise<SeoPageDetails> {
    const { content } = await this.#httpService.request<ApiItemBase<SeoPageDetails>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/seo/pages/${pageContext}-${this.#i18nLocale}-${slug}`,
    });

    return transform(content);
  }
}
