
import Vue from 'vue';

import IcFacebook from '@/assets/svg/ic_facebook.svg?inline';
import IcHouzz from '@/assets/svg/ic_houzz.svg?inline';
import IcInstagram from '@/assets/svg/ic_instagram.svg?inline';
import IcPinterest from '@/assets/svg/ic_pinterest.svg?inline';
import { ESocialType, ESocialUrl } from '@/domain/core/Social.enum';

export default Vue.extend({
  name: 'NavigationSocial',
  components: {
    IcFacebook,
    IcHouzz,
    IcInstagram,
    IcPinterest,
  },
  data() {
    return {
      socialItems: Object.freeze([
        { name: ESocialType.Facebook, link: ESocialUrl.Facebook, icon: `Ic${ESocialType.Facebook}` },
        { name: ESocialType.Instagram, link: ESocialUrl.Instagram, icon: `Ic${ESocialType.Instagram}` },
        { name: ESocialType.Pinterest, link: ESocialUrl.Pinterest, icon: `Ic${ESocialType.Pinterest}` },
        { name: ESocialType.Houzz, link: ESocialUrl.Houzz, icon: `Ic${ESocialType.Houzz}` },
      ]),
    };
  },
});
