import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { OrderRepositoryInterface } from '@/domain/order/orderRepository.interface';
import { LegacyCreateOrder, LegacyOrder, LegacyOrderPay, PaymentGateway } from '@/domain/order/types';
import HttpService from '@/services/http.service';

const resourcePath = '/orders';

export default class OrderRepository implements OrderRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async create(cartId: string): Promise<LegacyCreateOrder> {
    if (!cartId) {
      throw new Error('No cartId provided');
    }

    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCreateOrder>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}${resourcePath}`,
      payload: {
        cartId,
      },
    });
    const orderId = content?.content;

    if (!orderId) {
      throw new Error('No orderId retrieved');
    }

    return orderId;
  }

  async get(orderId: string): Promise<LegacyOrder> {
    if (!orderId) {
      throw new Error('No orderId provided');
    }

    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyOrder>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${orderId}`,
    });
    const order = content?.content;

    if (!order) {
      throw new Error(`No order data retrieved for order ${orderId}`);
    }

    return order;
  }

  async pay(orderId: string, paymentToken: string | number, gateway: PaymentGateway): Promise<LegacyOrderPay> {
    if (!orderId) {
      throw new Error('No orderId provided');
    }

    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyOrderPay>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}${resourcePath}/${orderId}/payment`,
      payload: {
        gateway,
        token: paymentToken,
      },
    });

    const payment = content?.content;

    if (!payment) {
      throw new Error(`No payment data retrieved for order ${orderId}`);
    }

    return payment;
  }

  async updateOrderAddress(orderId: string, addressId: string, type: 'shipping' | 'billing'): Promise<LegacyCreateOrder> {
    if (!orderId) {
      throw new Error('No orderId provided');
    }

    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCreateOrder>>({
      headers: {
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}${resourcePath}/${orderId}/addresses/${type}`,
      payload: {
        addressId,
      },
    });
    const updatedOrderId = content?.content;

    if (!updatedOrderId) {
      throw new Error('No updatedOrderId retrieved');
    }

    return updatedOrderId;
  }
}
