import { ValueOf } from 'type-fest';

import { Country } from '@/domain/address/types';
import { JobType, UserType } from '@/domain/auth/types';
import { UserPublic } from '@/domain/user/types';

export const EBadgeSellerLevel = {
  Pro: 'professional',
  ProAmbassador: 'proAmbassador',
  SuperPro: 'superPro',
} as const;

export type BadgeSellerLevelType = ValueOf<typeof EBadgeSellerLevel> | null;

export const EShopType = {
  Individual: 'private_person',
  Professional: 'professional',
} as const;

export type ShopType = ValueOf<typeof EShopType>;

const ECommissionType = {
  All: 'all',
  Listing: 'listing',
  Product: 'product',
  Shop: 'shop',
  Sold: 'sold',
} as const;

interface Commission {
  description: string;
  finishedAt: string;
  id: string;
  includingTaxes: number;
  isRetail: boolean;
  isSpecialOperation: boolean;
  label: string;
  locale: string;
  platform: string;
  shopType: ShopType;
  startedAt: string;
  type: ValueOf<typeof ECommissionType>;
  value: number;
}

export interface ShopStats {
  badgeLevel: 10 | 20 | 30 | null;
  daysOpeningShopTime: number;
  display: boolean;
  id: string;
  productsInSale: number;
  productsSold: number;
  rating: number | null;
  totalReviews: number;
}

export interface ShopDetails {
  activity: UserType | null;
  businessId: string | null;
  commissions?: Commission[];
  country: Country['code'];
  id: string;
  isInternational: boolean;
  job: JobType | null;
  legacyId: string;
  statistics?: ShopStats; // Embed
  type: ShopType;
  user?: UserPublic; // Embed
  userId: UserPublic['id'];
}

export type ShopId = ShopDetails['id'];

export interface ShopStatus {
  badgeLevel: ShopStats['badgeLevel'];
  id: ShopId;
  type: ShopDetails['type'];
}
