
import Vue from 'vue';

import IcCrossThick from '@/assets/svg/ic_cross_thick.svg?inline';
import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import IcSearch from '@/assets/svg/ic_search.svg?inline';

export default Vue.extend({
  name: 'InputSearch',
  components: {
    IcCrossThick,
    IcLoader,
    IcSearch,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    modelValue: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('update:value', value);
      },
    },
    shouldDisplayResetButton(): boolean {
      return !!this.modelValue && !this.isDisabled;
    },
  },
  methods: {
    async reset(): Promise<void> {
      this.modelValue = '';

      await this.$nextTick();

      this.$emit('reset', this.modelValue);
    },
    submit(): void {
      this.$emit('submit', this.modelValue);
    },
    handleFocus(event: Event): void {
      this.$emit('focus', event);
    },
  },
});
