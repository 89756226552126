import { Address } from '@/domain/address/types';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EEmbedsName } from '@/domain/core/api/embeds.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { ReviewToCollect } from '@/domain/review/types';
import { UserPrivate, UserPublic, UserStats, UserSubscriptions } from '@/domain/user/types';
import { UserRepositoryInterface } from '@/domain/user/userRepository.interface';
import { transform, transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const resourcePath = '/users';

export default class UserRepository implements UserRepositoryInterface {
  readonly #httpService: HttpService;
  readonly #basePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async get(id: UserPrivate['id']): Promise<UserPrivate> {
    const token = this.#security.getToken();
    const { content } = await this.#httpService.request<ApiItemBase<UserPrivate>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/users/${id}`,
    });

    return transform(content);
  }

  async getAddresses(id: UserPrivate['id']): Promise<Address[]> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiBase<Address>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}/addresses`,
      queryParams: {
        limit: 100,
      },
    });

    return transformList(content?.items);
  }

  async getPublicDetailsByEmail(email: UserPrivate['email']): Promise<UserPublic> {
    const { content } = await this.#httpService.request<ApiItemBase<UserPublic>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/email/${email}`,
    });

    return transform(content);
  }

  async getReviewsToCollect(id: UserPrivate['id']): Promise<ReviewToCollect[]> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiBase<ReviewToCollect>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}/reviews`,
      queryParams: {
        embeds: EEmbedsName.Seller,
      },
    });

    return transformList(content?.items);
  }

  async getStats(id: UserPrivate['id']): Promise<UserStats | null> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<UserStats>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}/stats`,
    });

    return transform(content);
  }

  async getSubscriptions(id: UserPrivate['id']): Promise<UserSubscriptions> {
    const token = this.#security.getToken();
    const { content } = await this.#httpService.request<ApiItemBase<UserSubscriptions>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/users/${id}/profile`,
    });

    return transform(content);
  }

  async updateSubscriptions(id: UserPrivate['id'], userProfile: Partial<UserSubscriptions>): Promise<UserSubscriptions> {
    const payload: UserSubscriptions = {
      isSubscribedNewsletter: !!userProfile?.isSubscribedNewsletter,
      isSubscribedSuggestedProducts: !!userProfile?.isSubscribedSuggestedProducts,
      isSubscribedTexts: !!userProfile?.isSubscribedTexts,
      isSubscribedWishlistNotifications: !!userProfile?.isSubscribedWishlistNotifications,
    };
    const token = this.#security.getToken();
    const { content } = await this.#httpService.request<ApiItemBase<UserSubscriptions>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}/users/${id}/profile`,
      payload,
    });

    return transform(content);
  }
}
