export const EMetaTags = {
  Author: 'author',
  Description: 'description',
  GoogleSiteVerification: 'google-site-verification',
  PinterestKey: 'p:domain_verify',
  Robots: 'robots',
  ThemeColor: 'theme-color',
  Title: 'title',
  Viewport: 'viewport',
} as const;

export const ETwitter = {
  Card: 'twitter:card',
  Description: 'twitter:description',
  Image: 'twitter:image',
  Site: 'twitter:site',
  Title: 'twitter:title',
} as const;

export const EOpenGraph = {
  Description: 'og:description',
  Image: 'og:image',
  SiteName: 'og:site_name',
  Title: 'og:title',
  Type: 'og:type',
  Url: 'og:url',
} as const;

export const ELinkType = {
  Alternate: 'alternate',
  Canonical: 'canonical',
  Preconnect: 'preconnect',
  Stylesheet: 'stylesheet',
} as const;

export const ECrossOriginAttributes = {
  Anonymous: 'anonymous',
  UseCredentials: 'use-credentials',
} as const;
