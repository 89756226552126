export const ELocalStorageItem = {
  AlgoliaAbTestInfos: 'algoliaAbTestInfos',
  DefaultBillingAddressId: 'defaultBillingAddresseId',
  DefaultDeliveryLocation: 'defaultDeliveryLocation',
  DefaultShippingAddressId: 'defaultShippingAddresseId',
  PurchaseEventPayload: 'purchaseEventPayload',
  SearchListRecent: 'searchListRecent',
  ShouldDisplayModalSearch: 'shouldDisplayModalSearch',
  StoredItemsViewed: 'storedItemsViewed',
} as const;
