import { ValueOf } from 'type-fest';

export const ECurrencyCode = {
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
} as const;

export type CurrencyCode = ValueOf<typeof ECurrencyCode>;

export interface CurrencyRate {
  code: string;
  rate: number;
}

export interface Price {
  amount: number;
  currency: CurrencyCode;
}
