import { CookieSerializeOptions } from 'cookie';
import { NuxtCookies } from 'cookie-universal-nuxt';

import { AuthRepositoryInterface } from '@/domain/auth/authRepository.interface';
import { AuthenticatedUser, LoginApplePayload, LoginPayload, RegisterPayload } from '@/domain/auth/types';
import { ECookie } from '@/domain/core/http/Cookie.enum';
import { EMaxAge } from '@/domain/core/http/MaxAge.enum';
import { UserPrivate } from '@/domain/user/types';

export interface AuthServiceInterface {
  login(payload: LoginPayload): Promise<UserPrivate>;
  loginWithApple(payload: LoginApplePayload): Promise<{ isNewUser: boolean; user: AuthenticatedUser }>;
  loginWithGoogle(token: string): Promise<{ isNewUser: boolean; user: AuthenticatedUser }>;
  register(payload: RegisterPayload): Promise<UserPrivate>;
  removeAuthenticationCookies(): void;
  setAuthenticationCookies(user: UserPrivate): void;
}

export class AuthService implements AuthServiceInterface {
  readonly #authRepository: AuthRepositoryInterface;
  readonly #cookies: NuxtCookies;

  constructor(authRepository: AuthRepositoryInterface, cookies: NuxtCookies) {
    this.#authRepository = authRepository;
    this.#cookies = cookies;
  }

  async login(payload: LoginPayload): Promise<UserPrivate> {
    const user = await this.#authRepository.login(payload);

    this.setAuthenticationCookies(user);

    return user;
  }

  async loginWithApple(payload: LoginApplePayload): Promise<{ isNewUser: boolean; user: AuthenticatedUser }> {
    const { isNewUser, user } = await this.#authRepository.loginWithApple(payload);

    this.setAuthenticationCookies(user);

    return { isNewUser, user };
  }

  async loginWithGoogle(token: string): Promise<{ isNewUser: boolean; user: AuthenticatedUser }> {
    const { isNewUser, user } = await this.#authRepository.loginWithGoogle(token);

    this.setAuthenticationCookies(user);

    return { isNewUser, user };
  }

  async register(payload: RegisterPayload): Promise<UserPrivate> {
    const user = await this.#authRepository.register(payload);

    this.setAuthenticationCookies(user);

    return user;
  }

  removeAuthenticationCookies(): void {
    this.#cookies.remove(ECookie.UserId);
    this.#cookies.remove(ECookie.UserToken);

    this.#cookies.remove(ECookie.UserCartId);
    this.#cookies.remove(ECookie.UserCurrentOrderId);
  }

  setAuthenticationCookies(user: UserPrivate): void {
    const { token, legacyId } = user;

    if (!token) {
      throw new Error('User token is missing');
    } else if (!legacyId) {
      throw new Error('User id is missing');
    }

    const options: CookieSerializeOptions = {
      maxAge: EMaxAge.TenDays,
      path: '/',
      sameSite: 'lax',
    };

    this.#cookies.set(ECookie.UserId, legacyId, options);
    this.#cookies.set(ECookie.UserToken, token, options);
  }
}
