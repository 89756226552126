import VueRouter from 'vue-router';

import { NavigationServiceInterface } from '@/domain/cms/navigationService.interface';
import { EMenuItemTargetType, MenuItem, MenuItemTarget } from '@/domain/cms/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { CategoryRouterInterface } from '@/infrastructure/product/categoryRouter';

export class NavigationService implements NavigationServiceInterface {
  readonly #obfuscationTag = '#obf#'; // NOTE: Fake obfuscation as the base 64 encoding creates some unmanageable side effects. This method simply tags links.
  readonly #categoryRouterService: CategoryRouterInterface;
  readonly #router: VueRouter;

  constructor(categoryRouterService: CategoryRouterInterface, router: VueRouter) {
    this.#categoryRouterService = categoryRouterService;
    this.#router = router;
  }

  computeMenuItemTargetHref = (target: MenuItemTarget): string => {
    let href = '';

    if (target?.type === EMenuItemTargetType.Category) {
      href = this.#router.resolve({
        name: ERouteName.Category,
        params: this.#categoryRouterService?.convertSlugsTreeToRouterParameters(target?.slugsTree) || {},
      }).href;
    } else if (target?.type === EMenuItemTargetType.Selection) {
      href = this.#router.resolve({
        name: ERouteName.Selections,
        params: {
          reference: target?.reference,
          slug: target?.slug,
        },
      }).href;
    } else {
      href = target?.value;
    }

    return href;
  };

  computeNavigationHrefs = (navigation: MenuItem[]): MenuItem[] => {
    const computeHrefs = (items: MenuItem[]): MenuItem[] => items.map((item) => {
      return {
        ...item,
        target: {
          ...item.target,
          href: this.computeMenuItemTargetHref(item.target || {}),
        },
        children: item.children ? computeHrefs(item.children) : [],
      };
    });

    return computeHrefs([...navigation]);
  };

  getObfuscationTag = (): string => this.#obfuscationTag;

  deobfuscateLink = (link?: string): string => link?.replace(this.#obfuscationTag, '') || '';

  isObfuscatedLink = (link?: string): boolean => !!link?.includes(this.#obfuscationTag);

  obfuscateLink = (link?: string): string => this.isObfuscatedLink(link) ? (link || '') : `${link}${this.#obfuscationTag}`;
}
