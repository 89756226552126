import { TranslateResult } from 'vue-i18n';
import { Route } from 'vue-router/types/router';

import { AlgoliaHitHighlightResult } from '@/domain/core/algolia/types';
import { ProductStatus, QualityLevel } from '@/domain/product/types';

export const maxBatchQuantityIndexed = 8;

export interface AisNumericMenuItem {
  end?: number;
  label: TranslateResult;
  start?: number;
}

export interface AlgoliaRouter {
  _onPopState?(event: any): void;
  _removeAfterEach?: Function;
  createURL(routeState: Route['query']): string;
  dispose(): void;
  onUpdate(arg: Function): void;
  read(): Route['query'];
  write(routeState: Route['query']): void;
}

export interface CurrentRefinementListItem {
  attribute: string;
  count?: number;
  exhaustive?: boolean;
  label: string;
  operator?: string;
  type: string;
  value: string | number;
}

export interface CurrentRefinementList {
  attribute: string;
  indexId: string;
  indexName: string;
  label: string;
  refinements?: CurrentRefinementListItem[];
}

// Source : https://www.algolia.com/doc/api-reference/widgets/pagination/vue/#widget-param-class-names
export interface InstantSearchPaginationClassNames {
  'ais-Pagination': string;
  'ais-Pagination--noRefinement': string;
  'ais-Pagination-item': string;
  'ais-Pagination-item--disabled': string;
  'ais-Pagination-item--firstPage': string;
  'ais-Pagination-item--lastPage': string;
  'ais-Pagination-item--nextPage': string;
  'ais-Pagination-item--page': string;
  'ais-Pagination-item--previousPage': string;
  'ais-Pagination-item--selected': string;
  'ais-Pagination-link': string;
  'ais-Pagination-list': string;
}

export interface RefinementListItem {
  count: number;
  highlighted: string;
  isRefined: boolean;
  label: string;
  value: string;
}

export interface FilterValues {
  configure?: Record<string, any>;
  hierarchicalMenu?: Record<string, string[]>;
  numericMenu?: Record<string, string>;
  page?: string;
  query?: string;
  range?: Record<string, string>;
  refinementList?: Record<string, string[]>;
  sortBy?: string;
  toggle?: Record<string, boolean>;
}

export interface AlgoliaHit {
  _highlightResult: AlgoliaHitHighlightResult;
  objectID: string;
  pageType: string;
  popularity: number;
  query: string;
  url?: string;
}

export interface AlgoliaIndexResults {
  abTestID?: string;
  abTestVariantID?: string;
  disjunctiveFacets?: any[];
  exhaustiveNbHits?: boolean;
  exhaustiveTypo?: boolean;
  facets?: any[];
  hierarchicalFacets?: any[];
  hits: AlgoliaHit[];
  hitsPerPage: number;
  index: string;
  nbHits?: number;
  nbPages?: number;
  page?: number;
  params?: string;
  processingTimeMS?: number;
  query?: string;
  queryAfterRemoval?: string;
}

export interface AlgoliaIndex {
  hits: AlgoliaHit[];
  indexId: string;
  indexName: string;
  results: AlgoliaIndexResults;
}

export type AlgoliaIndices = AlgoliaIndex[];

export interface UiState {
  [indexName: string]: FilterValues;
}

export type RouteState = Record<string, string | boolean | undefined>;

export interface StateMapping {
  routeToState: (routeState: RouteState) => UiState;
  stateToRoute: (uiState: UiState) => RouteState;
}

export interface AlgoliaProduct {
  __position: number;
  __queryID: string;
  authentified: boolean;
  batch: number;
  batchQuantity: number;
  boosted: boolean;
  categoryId: string;
  categoryLevel1: string;
  categoryLevel2: string;
  categoryLevel3: string;
  categoryLvl1Facet: string;
  categoryLvl2Facet: string;
  categoryLvl3Facet: string;
  categorySlug: string;
  categoryTitle: string;
  cdnRemoveBackground: boolean;
  colorFacet: string;
  colorId: string;
  colorSlug: string;
  colorTitle: string;
  conditionFacet: string;
  conditionId: string;
  conditionSlug: string;
  conditionTitle: string;
  country: string;
  createdAt: number;
  currency: string;
  currentPrice: number;
  department: string;
  depth: number;
  designerFacet: string | null;
  designerId: string | null;
  designerSlug: string | null;
  designerTitle: string | null;
  discarded: boolean;
  freeDelivery: boolean;
  hasFreeDeliveryOffer: boolean | null;
  height: number;
  id: string;
  isDiscounted: boolean;
  isFavorite: boolean;
  isFirst: boolean;
  isHandmade?: boolean;
  isNegotiable: boolean;
  isNewFavorite: boolean;
  isProPrice: boolean;
  isTradeProgram: boolean;
  materialFacet: string;
  materialId: string;
  materialSlug: string;
  materialTitle: string;
  name: string;
  objectID: string;
  pictureId: string;
  price: number;
  proPrice: number;
  publishedAt: number;
  qualityMark: QualityLevel;
  quantity: number;
  region: string | null;
  retail: boolean;
  retailLocation: string | null;
  sellerName: string;
  sellerScoreAverageRating: number;
  sellerScoreReviewsCount: number;
  shopId: string;
  sku: string;
  slug: string;
  specialPrice: number | null;
  status: ProductStatus;
  styleFacet: string;
  styleId: string;
  styleSlug: string;
  styleTitle: string;
  title: string;
  weight: number;
  width: number;
}
