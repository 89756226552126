import { render, staticRenderFns } from "./NavigationMain.vue?vue&type=template&id=f4b6438e"
import script from "./NavigationMain.vue?vue&type=script&lang=ts"
export * from "./NavigationMain.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationMainLink: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationMainLink.vue').default,NavigationMainSubmenu: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationMainSubmenu.vue').default})
