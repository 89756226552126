import { actionTree, mutationTree } from 'typed-vuex';

import { EShopType, ShopDetails, ShopStats, ShopStatus } from '@/domain/shop/types';
import { accessorType } from '@/store';

export type State = Pick<ShopStats, 'badgeLevel'>
  & Pick<ShopDetails, 'id' | 'type'>;

export const state = (): State => ({
  badgeLevel: null,
  type: EShopType.Individual,
  id: '',
});

export const mutations = mutationTree(state, {
  SET_SHOP_STATUS: (state, payload: ShopStatus): void => {
    state.badgeLevel = payload.badgeLevel;
    state.id = payload.id;
    state.type = payload.type;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchShopStatus(): Promise<void> {
    try {
      const accessor: typeof accessorType = this.app.$accessor;
      const { token, id } = accessor.user;

      if (!token) {
        throw new Error('User token is missing');
      }

      const shopScore = await this.$services.shopService.getShopStatus(id);

      accessor.shop.SET_SHOP_STATUS(shopScore);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
