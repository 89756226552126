import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { DeliveryRepositoryInterface, GetPickPointsPayload } from '@/domain/delivery/deliveryRepository.interface';
import { EProviderType, PickupPoint, PickupPointId } from '@/domain/delivery/types';
import { transform, transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const pickupPointsResourcePath = '/pickup-points';

export default class DeliveryRepository implements DeliveryRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;

  constructor(httpService: HttpService, basePath: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
  }

  async getPickupPoint(id: PickupPointId, provider = EProviderType.MondialRelay): Promise<PickupPoint> {
    const { content } = await this.#httpService.request<ApiItemBase<PickupPoint>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${pickupPointsResourcePath}/${provider}/${id}`,
    });

    return transform(content);
  }

  async getPickupPoints(payload: GetPickPointsPayload): Promise<PickupPoint[]> {
    const { content } = await this.#httpService.request<ApiBase<PickupPoint>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${pickupPointsResourcePath}`,
      queryParams: {
        ...payload,
      },
    });

    return transformList(content?.items);
  }
}
