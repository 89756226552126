
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';

import { productCarouselOptions } from '@/config/plugins/SsrCarouselOptions';
import { Product } from '@/domain/product/types';
import { BaseCarouselOptions } from '@/infrastructure/core/components/carousel/BaseCarousel.interface';
import { _ga4ProductAdapter } from '@/infrastructure/externalServices/ga4/adapters/ga4ProductAdapter';
import { generateItemListIdentifier } from '@/infrastructure/externalServices/ga4/generateItemListIdentifier';
import { GA4ECommerceItemList } from '@/infrastructure/externalServices/ga4/types';
import { EGtmEcommerceListName, EGtmEventEcommerce } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'CarouselProduct',
  props: {
    options: {
      type: Object as PropType<BaseCarouselOptions>,
      default: () => productCarouselOptions,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    trackingId: {
      type: String as PropType<ValueOf<typeof EGtmEcommerceListName>>,
      required: true,
    },
  },
  data() {
    return {
      localProducts: this.products, // Store initial products' list locally to be able to track potential changes.
    };
  },
  computed: {
    isUserPro(): boolean {
      return this.$accessor?.user?.isPro;
    },
    hasProducts(): boolean {
      return !!this.products?.length;
    },
    itemListIdentifier(): GA4ECommerceItemList {
      return generateItemListIdentifier(this.trackingId);
    },
  },
  mounted() {
    this.handleProductListTracking();
  },
  methods: {
    handleClickProduct(product: Product, position: number): void {
      // NOTE: Only track product list again if it has changed (e.g. products' list search preview).
      if (this.localProducts !== this.products) {
        this.handleProductListTracking();
      }

      const fmtProduct = _ga4ProductAdapter(product, position);

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.SelectItem,
        ecommerce: {
          ...this.itemListIdentifier,
          items: [{
            ...this.itemListIdentifier,
            ...fmtProduct,
          }],
        },
      });
    },
    handleProductListTracking(): void {
      if (!this.products?.length) {
        return;
      }

      const fmtProducts = this.products.map((product) => ({
        ..._ga4ProductAdapter(product),
        ...this.itemListIdentifier,
      }));

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.ViewItemList,
        ecommerce: {
          ...this.itemListIdentifier,
          items: fmtProducts,
        },
      });
    },
  },
});
