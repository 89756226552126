import { ValueOf } from 'type-fest';

import { SecurityToken } from '@/domain/core/SecurityService.interface';

export const EUserRole = {
  Admin: 'ROLE_ADMIN',
  B2BPro: 'ROLE_B2B_PRO',
  Expert: 'ROLE_PRODUCT_EXPERT',
  ProductBooster: 'ROLE_PRODUCT_BOOSTER',
  SelectionsManager: 'ROLE_SELECTIONS_MANAGER',
  User: 'ROLE_USER',
} as const;

interface OrderEntries {
  cashoutPrepared: number;
  confirmed: number;
  pending: number;
}

export interface UserPublic {
  firstName: string;
  hashedUser: string;
  id: string;
  isLocked: boolean;
  lastNameInitial: string;
  legacyId: number | null;
}

export interface UserPrivate {
  email: string;
  firstName: string;
  hashedUser: string;
  id: string;
  isLocked: boolean;
  isPro: boolean;
  lastName: string;
  lastNameInitial: string;
  legacyId: number | null;
  locale: string;
  profileId: string;
  roles: ValueOf<typeof EUserRole>[];
  shopId: string | null;
  source: string;
  token: SecurityToken;
}

export interface UserSubscriptions {
  isSubscribedNewsletter: boolean;
  isSubscribedSuggestedProducts: boolean;
  isSubscribedTexts: boolean;
  isSubscribedWishlistNotifications: boolean;
}

export interface UserStats {
  orderEntries: OrderEntries;
}

export interface LegacyUser {
  email: string;
  firstname?: string;
  id?: string;
  lastname?: string;
  locale: string;
  status: string;
  token?: string;
  user_id?: string;
}
