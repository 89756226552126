
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';

import IcLoader from '@/assets/svg/ic_loader.svg?inline';

const EButtonType = {
  Button: 'button',
  Submit: 'submit',
} as const;

type ButtonType = ValueOf<typeof EButtonType>;

export default Vue.extend({
  name: 'BaseButton',
  components: {
    IcLoader,
  },
  props: {
    buttonType: {
      type: String as PropType<ButtonType>,
      default: EButtonType.Button,
      validator: (value: ButtonType): boolean => {
        return Object.values(EButtonType).includes(value);
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isSecondaryLight: {
      type: Boolean,
      default: false,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event: Event): void {
      this.$emit('click', event);
    },
  },
});
