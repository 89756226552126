import { ValueOf } from 'type-fest';

export interface AlgoliaHighlightResult {
  fullyHighlighted?: boolean;
  matchLevel?: string;
  matchedWords?: string[];
  value: string;
}
export interface AlgoliaHitHighlightResult {
  query: AlgoliaHighlightResult;
}

export interface DocumentSet<T> {
  documents: T;
  total: number;
}

export interface GeolocationQuerySuggestionDocument {
  _highlightResult: {
    admin_code1: AlgoliaHighlightResult;
    admin_code2: AlgoliaHighlightResult;
    admin_code3: AlgoliaHighlightResult;
    place_name: AlgoliaHighlightResult;
    postal_code: AlgoliaHighlightResult;
  };
  country_code: string;
  objectID: string;
  place_name: string;
  postal_code: string;
}

export type GeolocationQuerySuggestionDocumentSet = DocumentSet<GeolocationQuerySuggestionDocument[]>;

export interface QuerySuggestionDocument {
  _highlightResult: AlgoliaHitHighlightResult;
  objectID: string;
  pageType: 'search' | 'category' | 'query';
  query: string;
  url: string;
}

export type QuerySuggestionDocumentSet = DocumentSet<QuerySuggestionDocument[]>;

export const EAlgoliaCoreSearchCriteria = {
  HierarchicalMenu: 'hierarchicalMenu',
  Page: 'page',
  Query: 'query',
  SortBy: 'sortBy',
} as const;

// NOTE: Custom query parameter name used to enhance URLs readability and avoid bloated URLs like 'hierarchicalMenu[hierarchicalCategories.lvl1][0]=foo' and so on.
// WARNING: This is not an actual search criteria. 'hierarchicalMenu' is.
export const HierarchicalMenuCategoriesSearchCriteria = `${EAlgoliaCoreSearchCriteria.HierarchicalMenu}.categories` as const;

export const EFacetNameRefinementList = {
  CategoryId: 'categoryId',
  CategoryLevel1: 'categoryLvl1Title',
  CategoryLevel2: 'categoryLvl2Title',
  CategoryLevel3: 'categoryLvl3Title',
  ColorFacet: 'colorFacet',
  ColorSlug: 'colorSlug',
  Country: 'country',
  Department: 'departmentFacet',
  DesignerSlug: 'designerSlug',
  DesignerTitle: 'designerTitle',
  HierarchicalCategoriesLevel1: 'hierarchicalCategories.lvl1',
  HierarchicalCategoriesLevel2: 'hierarchicalCategories.lvl2',
  HierarchicalCategoriesLevel3: 'hierarchicalCategories.lvl3',
  IsHandmade: 'isHandmade',
  MaterialSlug: 'materialSlug',
  MaterialTitle: 'materialTitle',
  QualityMark: 'qualityMark',
  Quantity: 'batchQuantity',
  Region: 'region',
  Sku: 'sku',
  StyleSlug: 'styleSlug',
  StyleTitle: 'styleTitle',
} as const;

export type FacetNameRefinementList = ValueOf<typeof EFacetNameRefinementList>;

export const EFacetNameToggleRefinement = {
  IsDeliveryBySelency: 'isDeliveryBySelency',
  IsDeliveryBySeller: 'isDeliveryBySeller',
  IsDeliveryCocolis: 'isDeliveryCocolis',
  IsDeliveryMondialRelay: 'isDeliveryMondialRelay',
  IsDeliveryColissimo: 'isDeliveryColissimo',
  IsDeliveryCustom: 'isDeliveryCustom',
  IsDiscounted: 'isDiscounted',
  IsNegotiable: 'isNegotiable',
  IsProPrice: 'isProPrice',
  IsSellerPro: 'isSellerPro',
  IsTradeProgram: 'isTradeProgram',
  Retail: 'retail',
  Status: 'status',
} as const;

export type FacetNameToggleRefinement = ValueOf<typeof EFacetNameToggleRefinement>;

export const EFacetNameRange = {
  CurrentPrice: 'currentPrice',
  Depth: 'depth',
  Height: 'height',
  Width: 'width',
} as const;

export type FacetNameRange = ValueOf<typeof EFacetNameRange>;

export const EFacetNameNumericMenu = {
  Discount: 'discount',
  PublishedAt: 'publishedAt',
  SellerNote: 'sellerNote',
} as const;

export type FacetNameNumericMenu = ValueOf<typeof EFacetNameNumericMenu>;

export const EFacetBusinessScore = {
  Default: 'business_score_abtest',
  Pro: 'business_score_pro_abtest',
} as const;

export const ESearchInsightEvent = {
  AddToCart: 'add_to_cart',
  AddToWishlist: 'add_to_wishlist',
  ContactTheSeller: 'contact_the_seller_from_product_page',
  Negotiate: 'negotiate_from_product_page',
  OpenProductPage: 'open_product_page',
} as const;

export type SearchInsightEvent = ValueOf<typeof ESearchInsightEvent>;

export const ESearchInsightEventType = {
  Click: 'clickedObjectIDsAfterSearch',
  Conversion: 'convertedObjectIDsAfterSearch',
} as const;

export const EHitsThreshold = {
  Max: 60,
  Min: 9,
} as const;
