export const EMaxAge = {
  ZeroSeconds: 0,
  OneMinute: 60,
  get FifteenMinutes() {
    return this.OneMinute * 15;
  },
  get OneHour() {
    return this.OneMinute * 60;
  },
  get OneDay() {
    return this.OneHour * 24;
  },
  get MaxAllowedByGDPR() {
    return this.OneDay * 395;
  },
  get OneMonth() {
    return this.OneDay * 30;
  },
  get SixMonths() {
    return this.OneMonth * 6;
  },
  get TenDays() {
    return this.OneDay * 10;
  },
  get TwoDays() {
    return this.OneDay * 2;
  },
} as const;
