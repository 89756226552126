
import Vue from 'vue';

import IcBCorpTaglineLockupStandardsEN from '@/assets/svg/ic_bcorp_tagline_lockup_standards_en.svg?inline';
import IcBCorpTaglineLockupStandardsFR from '@/assets/svg/ic_bcorp_tagline_lockup_standards_fr.svg?inline';
import { ELanguage } from '@/domain/core/Language.enum';
import { EGtmEventUser, ENewsletterSubmitLocation } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'FooterMain',
  components: {
    IcBCorpTaglineLockupStandardsFR,
    IcBCorpTaglineLockupStandardsEN,
  },
  computed: {
    isFrenchLocale(): boolean {
      return this.$i18n.locale === ELanguage.FR;
    },
  },
  methods: {
    handleTracking(): void {
      this.$gtm.push({
        event: EGtmEventUser.NewsletterOptin,
        location: ENewsletterSubmitLocation.Footer,
      });
    },
  },
});
