import { actionTree, mutationTree } from 'typed-vuex';

import { MenuItem } from '@/domain/cms/types';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { accessorType } from '@/store';
import { _isCurrentDateBetweenTwoDates } from '@/utilities/dates/isCurrentDateBetweenTwoDates';

export interface State {
  headerBannerContent: string | null;
  navigation: MenuItem[];
}

export const state = (): State => ({
  navigation: [],
  headerBannerContent: null,
});

export const mutations = mutationTree(state, {
  SET_NAVIGATION: (state, payload: MenuItem[]): void => {
    state.navigation = payload;
  },
  SET_HEADER_BANNER: (state, payload: string): void => {
    state.headerBannerContent = payload;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchHeaderContent(): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;

    try {
      const { colorTheme, topBlock } = await this.$repositories.edito.getHeader();

      const isColorThemeActive = colorTheme?.active
        && colorTheme.locale === this.$i18n.locale
        && _isCurrentDateBetweenTwoDates({ start: colorTheme.startingAt, end: colorTheme.endingAt });

      accessor.ui.setAccentColor({
        color: colorTheme?.color,
        fontColor: colorTheme?.fontColor,
        isActive: isColorThemeActive,
      });
      accessor.header.SET_HEADER_BANNER(topBlock?.content);
    } catch (err) {
      this.app.$errorMonitor.report((err as HttpResponse)?.content || err, 'fatal');
    }
  },
  async fetchNavigation(): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;

    try {
      const navigation = await this.$repositories.cms.getMenu();
      const navigationWithHrefs = this.app.$services?.navigation?.computeNavigationHrefs(navigation);

      accessor.header.SET_NAVIGATION(navigationWithHrefs);
    } catch (err) {
      this.app.$errorMonitor.report((err as HttpResponse)?.content || err, 'fatal');
    }
  },
});
