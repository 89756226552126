
import Vue from 'vue';

import { ERouteName } from '@/domain/core/Routes.enum';
import { NavigationStepLink } from '@/infrastructure/core/components/navigation/NavigationStepLink.interface';

export default Vue.extend({
  name: 'NavigationStepsCheckout',
  data() {
    const baseI18nPath = 'page.checkout.navigation';
    const steps = {
      cart: {
        href: this.$router.resolve({ name: ERouteName.CheckoutCart }).href,
        icon: () => import('@/assets/svg/ic_cart.svg?inline'),
        ids: [ERouteName.CheckoutCart],
        label: this.$t(`${baseI18nPath}.${ERouteName.CheckoutCart}.title`),
      },
      delivery: {
        href: this.$router.resolve({ name: ERouteName.CheckoutDelivery }).href,
        icon: () => import('@/assets/svg/ic_truck.svg?inline'),
        ids: [ERouteName.CheckoutDelivery],
        label: this.$t(`${baseI18nPath}.${ERouteName.CheckoutDelivery}.title`),
      },
      payment: {
        href: this.$router.resolve({ name: ERouteName.CheckoutPayment }).href,
        icon: () => import('@/assets/svg/ic_credit_card.svg?inline'),
        ids: [ERouteName.CheckoutPayment],
        label: this.$t(`${baseI18nPath}.${ERouteName.CheckoutPayment}.title`),
      },
      success: {
        ids: [ERouteName.CheckoutSuccess],
      },
    } as unknown as Record<string, NavigationStepLink>;

    return {
      steps: [
        steps.cart,
        steps.delivery,
        steps.payment,
        steps.success,
      ],
    };
  },
  computed: {
    currentRouteName(): string {
      return this.$route?.name || '';
    },
    currentStepIndex(): number {
      return this.steps.findIndex(({ ids }) => ids.includes(this.currentRouteName));
    },
    isLastStep(): boolean {
      return this.currentStepIndex === this.steps.length - 1;
    },
  },
});
