import { CookieValue } from 'cookie-universal-nuxt';
import { ValueOf } from 'type-fest';

export const ECookie = {
  AppDownloadPromptDismissed: 'appDownloadPromptDismissed',
  CsrfToken: 'csrfToken',
  Currency: 'currency',
  I18nRedirected: 'i18n_redirected',
  Language: 'language',
  NewsletterModalDismissed: 'accept_newsletter', // NOTE: Legacy cookie - its value doesn't matter.
  UserAbTests: 'userAbTests',
  UserCartId: 'userCartId',
  UserCurrentOrderId: 'userCurrentOrderId',
  UserId: 'userId',
  UserSegmentId: 'userSegmentId',
  UserToken: 'userToken',
} as const;

export type AllCookies = Record<ValueOf<typeof ECookie>, CookieValue>;
