import { ShopRepositoryInterface } from '@/domain/shop/shopRepository.interface';
import { BadgeSellerLevelType, EBadgeSellerLevel, EShopType, ShopStats, ShopStatus } from '@/domain/shop/types';
import { UserPublic } from '@/domain/user/types';

export interface ShopServiceInterface {
  getBadgeLevelTitle: (badgeLevel: ShopStats['badgeLevel']) => BadgeSellerLevelType | null;
  getShopStatus: (userId: UserPublic['id']) => Promise<ShopStatus>;
}

export class ShopService implements ShopServiceInterface {
  readonly #shopRepository: ShopRepositoryInterface;

  constructor(shopRepository: ShopRepositoryInterface) {
    this.#shopRepository = shopRepository;
  }

  getBadgeLevelTitle(badgeLevel: ShopStats['badgeLevel']): BadgeSellerLevelType | null {
    const sellerLevelThresholds: Record<number, BadgeSellerLevelType> = {
      10: EBadgeSellerLevel.Pro,
      20: EBadgeSellerLevel.SuperPro,
      30: EBadgeSellerLevel.ProAmbassador,
    };
    let title: BadgeSellerLevelType | null = null;

    if (Object.keys(sellerLevelThresholds).includes(`${badgeLevel}`)) {
      title = sellerLevelThresholds[badgeLevel!];
    }

    return title;
  }

  async getShopStatus(userId: UserPublic['id']): Promise<ShopStatus> {
    let badgeLevel = null;

    const { id, type } = await this.#shopRepository.getShopByUserId(userId);

    if (type === EShopType.Professional) {
      const stats = await this.#shopRepository.getShopStats(id);

      badgeLevel = stats?.badgeLevel || null;
    }

    return {
      badgeLevel,
      type,
      id,
    };
  }
}
