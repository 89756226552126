import { CmsRepositoryInterface } from '@/domain/cms/cmsRepository.interface';
import { EStaticAsset, HandmadeCategory, MenuItem, MerchandisingBlock, SearchQuery } from '@/domain/cms/types';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { transform, transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

// WARNING: this is a workaround for the API inconsistency.
// NOTE: These blocks are wrapped as an array of embeds but are not part of an 'items' key as usual.
// Therefore, transformList() will not work here. We need to map the MenuItem[] and apply transform() to the each merchandisingBlocks' items.
// Only the first level of menu items benefit from merchanding blocks.
type MenuItemPayload = Omit<MenuItem, 'merchandisingBlocks'> & {
  merchandisingBlocks: ApiItemBase<MerchandisingBlock>[];
};

const domainPath = '/cms';
const staticAssetPath = '/static-asset';

export default class CmsRepository implements CmsRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;

  constructor(
    httpService: HttpService,
    basePath: string,
  ) {
    this.#basePath = basePath;
    this.#httpService = httpService;
  }

  async getHandmadeCategories(): Promise<HandmadeCategory[]> {
    const { content } = await this.#httpService.request<ApiBase<HandmadeCategory>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}${staticAssetPath}/${EStaticAsset.HandmadeCategories}`,
    });

    return transformList(content?.items);
  }

  async getMenu(): Promise<MenuItem[]> {
    const { content } = await this.#httpService.request<ApiBase<MenuItemPayload>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/menu/current`,
    });

    const items = transformList(content?.items);
    const itemsWithUnwrappedMerchandisingBlocks: MenuItem[] = items?.map((item) => ({
      ...item,
      merchandisingBlocks: item?.merchandisingBlocks?.map((block) => transform(block)) || [],
    })) || [];

    return itemsWithUnwrappedMerchandisingBlocks;
  }

  async getPopularSearchQueries(): Promise<SearchQuery[]> {
    const { content } = await this.#httpService.request<ApiBase<SearchQuery>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}${staticAssetPath}/${EStaticAsset.PopularSearches}`,
    });

    return transformList(content?.items);
  }
}
