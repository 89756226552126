import { ValueOf } from 'type-fest';

export const EInputType = {
  Email: 'email',
  Number: 'number',
  Password: 'password',
  Search: 'search',
  Text: 'text',
} as const;

export type InputType = ValueOf<typeof EInputType>;
