
import Vue, { PropType } from 'vue';

import IcStarWireframe from '@/assets/svg/ic_star_wireframe.svg?inline';
import { ESchema, ESchemaAggregateRating, ESchemaProduct } from '@/domain/core/Schema.enum';
import { Product } from '@/domain/product/types';
import { ShopStats } from '@/domain/shop/types';

export default Vue.extend({
  name: 'ProductRating',
  components: {
    IcStarWireframe,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  data() {
    return {
      ESchema: Object.freeze(ESchema),
      ESchemaAggregateRating: Object.freeze(ESchemaAggregateRating),
      ESchemaProduct: Object.freeze(ESchemaProduct),
    };
  },
  computed: {
    rating(): ShopStats['rating'] {
      return this.product?.shop?.statistics?.rating || null;
    },
    reviewCount(): ShopStats['totalReviews'] {
      return this.product?.shop?.statistics?.totalReviews || 0;
    },
    sellerName(): string | null {
      const { firstName, lastNameInitial } = this.product?.shop?.user || {};
      let name = null;

      if (firstName && lastNameInitial) {
        name = `${firstName} ${lastNameInitial}`;
      }

      return name;
    },
  },
});
