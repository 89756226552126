
import Vue from 'vue';

import IcBurgerMenu from '@/assets/svg/ic_burger_menu.svg?inline';
import IcCart from '@/assets/svg/ic_cart.svg?inline';
import IcHeart from '@/assets/svg/ic_heart.svg?inline';
import Logo from '@/assets/svg/logo.svg?inline';
import LogoPro from '@/assets/svg/logo_pro.svg?inline';
import { MenuItem } from '@/domain/cms/types';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

const EHeaderBlockType = {
  Banner: 'banner',
  Pro: 'pro',
} as const;

export default Vue.extend({
  name: 'HeaderMain',
  components: {
    IcBurgerMenu,
    IcCart,
    IcHeart,
    Logo,
    LogoPro,
  },
  data() {
    return {
      EHeaderBlockType: Object.freeze(EHeaderBlockType),
      FormLogin: {
        component: () => import('@/components/organisms/form/FormLogin.vue'),
        id: ESidebarComponentId.FormLogin,
      },
      hideAfter: 160,
      isHidden: false,
      isSticky: false,
      lastScrollPosition: 0,
      scrollOffset: 80,
      SidebarNavigationMain: {
        component: () => import('@/components/molecules/sidebar/SidebarNavigationMain.vue'),
        id: ESidebarComponentId.SidebarNavigationMain,
      },
    };
  },
  computed: {
    cartPageHref(): string {
      return this.$router.resolve({ name: ERouteName.CheckoutCart }).href;
    },
    professionalsPageHref(): string {
      return this.$router.resolve({ name: ERouteName.Professionals }).href;
    },
    isUserAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
    mainNavigation(): MenuItem[] {
      return this.$accessor.header.navigation;
    },
    navLinkComponent(): string {
      return this.isUserAuthenticated ? 'BaseLink' : 'SidebarToggle';
    },
    isNavigationSidebarOpen(): boolean {
      return this.$accessor.ui.sidebar.currentComponent?.id === ESidebarComponentId.SidebarNavigationMain;
    },
    userCartItemsCount(): number {
      return this.$accessor.notifications.cart.itemsCount;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(): void {
      const scrollPosition = window.scrollY;

      if (scrollPosition > this.scrollOffset && (this.$device.isMobile || !this.isSticky)) {
        this.isSticky = true;
      } else if (this.isSticky && scrollPosition <= this.scrollOffset) {
        this.isSticky = false;
      }

      if (this.$device.isMobile) {
        this.isHidden = scrollPosition > this.lastScrollPosition && scrollPosition > this.hideAfter;
      }

      this.lastScrollPosition = scrollPosition;
    },
    handleTracking(type: string, $event: Event): void {
      const { textContent, href } = $event.currentTarget as HTMLAnchorElement;
      let event = null;

      if (type === EHeaderBlockType.Pro) {
        event = EGtmEventHeader.ClickProLink;
      }

      if (event) {
        this.$gtm.push({
          event,
          name: textContent,
          url: href,
        });
      }
    },
  },
});
