import { AuthRepositoryInterface } from '@/domain/auth/authRepository.interface';
import { AuthenticatedUser, LoginApplePayload, LoginPayload, RegisterPayload } from '@/domain/auth/types';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { EHttpStatusCode } from '@/domain/core/http/StatusCode.enum';
import { LegacyUser, UserPrivate } from '@/domain/user/types';
import { GoogleToken } from '@/infrastructure/auth/types';
import { transform } from '@/infrastructure/core/apiData.transformer';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import HttpService from '@/services/http.service';

export default class AuthRepository implements AuthRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #i18nLocale: string;
  readonly #legacyBasePath: string;

  constructor(httpService: HttpService, basePath: string, legacyBasePath: string, i18nLocale: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#i18nLocale = i18nLocale;
    this.#legacyBasePath = legacyBasePath;
  }

  async login(payload: LoginPayload): Promise<UserPrivate> {
    const { content } = await this.#httpService.request<ApiItemBase<UserPrivate>>({
      payload,
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/auth/login`,
    });

    return transform(content);
  }

  async loginWithApple(payload: LoginApplePayload): Promise<{ isNewUser: boolean; user: AuthenticatedUser }> {
    const { content, statusCode } = await this.#httpService.request<ApiItemBase<AuthenticatedUser>>({
      payload,
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/auth/login/apple`,
    });

    return {
      isNewUser: this.#isNewUser(statusCode),
      user: transform(content),
    };
  }

  async loginWithGoogle(token: GoogleToken): Promise<{ isNewUser: boolean; user: AuthenticatedUser }> {
    const { content, statusCode } = await this.#httpService.request<ApiItemBase<AuthenticatedUser>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/auth/login/google`,
      payload: {
        token,
      },
    });

    return {
      isNewUser: this.#isNewUser(statusCode),
      user: transform(content),
    };
  }

  async register(payload: RegisterPayload): Promise<UserPrivate> {
    const { content } = await this.#httpService.request<ApiItemBase<UserPrivate>>({
      payload,
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/auth/register`,
    });

    return transform(content);
  }

  async subscribeNewsletter(email: string, recaptchaToken: string): Promise<LegacyUser> {
    const payload = {
      email,
      locale: this.#i18nLocale,
      'g-recaptcha-response': recaptchaToken,
    };

    const request = await this.#httpService.request<ApiLegacyBase<LegacyUser>>({
      payload,
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}/users/newsletter/subscribe`,
    });

    if (request?.content?.succeed === false) {
      throw request;
    }

    return request?.content?.content;
  }

  #isNewUser(statusCode: HttpResponse['statusCode']): boolean {
    return statusCode === EHttpStatusCode.Created;
  }
}
