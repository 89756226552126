import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/Method.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { ReviewRepositoryInterface } from '@/domain/review/reviewRepository.interface';
import { OrderEntryReview } from '@/domain/review/types';
import { transform } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const resourcePath = '/order-entry-reviews';

export default class ReviewRepository implements ReviewRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async update(payload: Pick<OrderEntryReview, 'comment' | 'id' | 'rating'>): Promise<OrderEntryReview> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<OrderEntryReview>>({
      headers: {
        [EHttpHeader.Accept]: EApiVersion.V2,
        [EHttpHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Patch,
      path: `${this.#basePath}${resourcePath}/${payload.id}`,
      payload,
    });

    return transform(content);
  }
}
