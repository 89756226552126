export const ERouteName = {
  Account: 'account',
  AccountReviews: 'account-reviews',
  Badge: 'badge',
  BotmindContactForm: 'botmind-contact-form',
  Category: 'category',
  CdnError: 'cdn-error',
  Checkout: 'checkout',
  CheckoutCart: 'checkout-cart',
  CheckoutDelivery: 'checkout-delivery',
  CheckoutPayment: 'checkout-payment',
  CheckoutSuccess: 'checkout-success',
  Contact: 'contact',
  Designer: 'designers-slug',
  Designers: 'designers',
  DesignersList: 'designers-list',
  Handmade: 'handmade',
  Homepage: 'index',
  LatestFinds: 'latestFinds',
  PreviewHomepageId: 'preview-homepage-id',
  Professionals: 'professionals',
  Product: 'p-sku-slug',
  ProductSimilars: 'p-sku-slug-similars',
  SalesWinter: 'sales-winter',
  Search: 'search',
  SearchSeo: 'searchSeo',
  Selections: 'selections-reference-slug',
  ShopsId: 'shops-id',
  WishlistsBoardsId: 'wishlists-wishlistId-boards-boardId',
} as const;

export const ERouteBasePath = {
  Account: '/account',
  Category: '/c',
  Checkout: '/checkout',
  Product: '/p',
  Selections: '/selections',
} as const;

export const CatalogRoutesName = [
  ERouteName.Category,
  ERouteName.Search,
  ERouteName.ShopsId,
  ERouteName.Designers,
  ERouteName.LatestFinds,
] as const;
